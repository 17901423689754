import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import MuiLinkComponent from '@mui/material/Link/Link.js';

import { ILinkProps } from './models/ILinkProps';

const Translate = components.base.Translate.Translate;

export const Link: React.FC<ILinkProps> = ({ href, text, target, styles }) => {
	return (
		<MuiLinkComponent sx={styles} href={href} target={target}>
			<Translate>{text}</Translate>
		</MuiLinkComponent>
	);
};
