import React from 'react';
import styled from 'styled-components';

import { AppStore } from '../App/stores/AppStore.js';

const DebugIcon = (props: { className?: string; appStore?: AppStore }) => {
	const { className = '', appStore } = props;
	if (!appStore || !appStore.isDebug) {
		return null;
	}
	return (
		<div title="Log debug information" className={className} onClick={() => appStore.logDebugInfo()}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			>
				<rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect>
				<rect x="9" y="9" width="6" height="6"></rect>
				<line x1="9" y1="1" x2="9" y2="4"></line>
				<line x1="15" y1="1" x2="15" y2="4"></line>
				<line x1="9" y1="20" x2="9" y2="23"></line>
				<line x1="15" y1="20" x2="15" y2="23"></line>
				<line x1="20" y1="9" x2="23" y2="9"></line>
				<line x1="20" y1="14" x2="23" y2="14"></line>
				<line x1="1" y1="9" x2="4" y2="9"></line>
				<line x1="1" y1="14" x2="4" y2="14"></line>
			</svg>
		</div>
	);
};

const DebugIconStyled = styled<any>(DebugIcon)`
	display: ${(props) => (props.appStore && props.appStore.isDebug ? 'block' : 'none')};
	height: 16px;
	width: 16px;
	color: #888888;
	float: right;
	z-index: 100000;
`;

export default DebugIconStyled;
