import {stores } from '@kurtosys/app-start';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { action, computed, makeObservable, observable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { ILinkConfig } from '../models/ILinkConfig.js';
import { ILinkProps } from '../models/ILinkProps.js';
import { ILinkRefConfig } from '../models/ILinkRefConfig.js';
type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;
const StoreBase = stores.base.StoreBase<IConfiguration, IComponentStyles>;

export class LinkStore extends StoreBase {
	@observable linkMap: Map<string, ILinkProps> = new Map();

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore() {
		return this.storeContext.get<AppStore>('appStore');
	}

	@action
	async initialize(): Promise<void> {
		if (this.featuresConfiguration?.links) {
			const { links } = this.featuresConfiguration;
			for (const link of links) {
				this.linkMap.set(link.key, this.getLink(link.config));
			}
		}
	}

	@computed
	get featuresConfiguration() {
		return this.appStore.appComponentConfiguration?.features;
	}

	@computed
	get queryStore(): QueryStore {
		return this.storeContext.get<QueryStore>('queryStore');
	}

	getLink = (link: ILinkConfig): ILinkProps => {
		return {
			href: this.appStore?.redirectHelper?.getRedirectPath(link.redirect),
			text: this.queryStore?.query(link.textQuery),
		};
	};

	getLinks = (linkRefs: ILinkRefConfig[]): ILinkProps[] => {
		const links: ILinkProps[] = [];
		for (const linkRef of linkRefs) {
			if (linkRef.redirect) {
				links.push(this.getLink(linkRef as ILinkConfig));
			} else if (this.linkMap.has(linkRef.key)) {
				links.push(this.linkMap.get(linkRef.key));
			}
		}

		return links;
	};
}
