import {loadExternalScripts, Manifest, TemplateStore } from '@kurtosys/app-loader';
import { ITemplateConfiguration } from '@kurtosys/types/app/ITemplateConfiguration.js';

if (typeof (window as any).global === 'undefined') {
	(window as any).global = window;
}
const templateConfig = import('./configuration/templateConfig.json') as unknown as Promise<ITemplateConfiguration>;

export const TEMPLATE_STORE = new TemplateStore(templateConfig);

async function initialize() {
	await TEMPLATE_STORE.initialize();
	const manifest = new Manifest(TEMPLATE_STORE.templateConfiguration);

	/**
	 * The start call back function that will fire once external scripts have been setup.
	 * We need to orchestrate some of the imports here so that it can be injected into the common Start class
	 */
	async function startCallBack() {
		const { start } = await import('@kurtosys/app-start');
		const { Start } = start;
		const { Feature } = await import('./components/shared/Feature.js');
		const { getStoreContextForElement } = await import('./utils/store.js');
		const appImportFn = async () => await import('./components/App/App.js');

		const startApp = new Start(Feature, manifest, appImportFn, getStoreContextForElement);

		startApp.go();
	}

	await loadExternalScripts(
		manifest,
		startCallBack,
		TEMPLATE_STORE.externalScriptsBasePath || manifest.getBaseUrl(''),
		TEMPLATE_STORE.cacheOptions,
	);
}

initialize();
