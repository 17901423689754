import React from 'react';
import { query } from '@kurtosys/ksys-app-template';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { ILayout } from '../App/models/ILayout.js';
import { AppStore } from '../App/stores/AppStore.js';
import { CloseButton } from '../CloseButton/CloseButton.js';
import { Disclaimers } from '../Disclaimers/Disclaimers.js';
import { useMuiStyles } from '../hooks/useMuiStyles.js';
import { useStores } from '../hooks/useStores.js';
import { Links } from '../Links/Links.js';
import { ILinkProps } from '../Links/models/ILinkProps.js';
import { MuiDropdown } from '../MuiDropdown/MuiDropdown.js';

import { MuiActions } from './components/MuiActions/MuiActions.js';
import { MuiCheckbox } from './components/MuiCheckbox/MuiCheckbox.js';
import { MuiSelection } from './components/MuiSelection/MuiSelection.js';
import { MuiStepperSteps } from './components/MuiStepperSteps/MuiStepperSteps.js';
import { MuiTextDisplay } from './components/MuiTextDisplay/MuiTextDisplay.js';
import { IItemBasicProps } from './models/interfaces/IItemProps.js';
import { IMuiStepperProps } from './models/interfaces/IMuiStepperProps.js';
import { IMuiStepperStyles } from './models/interfaces/IMuiStepperStyles.js';
import { TAttestationKey } from './models/types/TAttestationKey.js';
import {
	TGridStepBodyComponentProps,
	TGridStepFooterComponentProps,
	TGridStepHeaderComponentProps,
} from './models/types/TGridStepComponentProps.js';

export const MuiStepper: React.FC<IMuiStepperProps> = (props) => {
	const { steps, allAcceptanceChecked, userSelectedValues, defaultActiveStep } = props;

	const [activeStep, setActiveStep] = React.useState(defaultActiveStep);
	const [filteredSteps, setFilteredSteps] = React.useState<IMuiStepperProps['steps']>([]);

	const appStore = useStores<AppStore>('appStore');
	React.useEffect(() => {
		const filteredSteps = steps.filter((step) => {
			const { conditional } = step;
			if (!conditional) {
				return true;
			}
			return appStore.isConditionValid(conditional);
		});
		setFilteredSteps(filteredSteps);
	}, [appStore, steps]);

	if (!filteredSteps.length) {
		return null;
	}

	const handleStep = (step: number) => {
		setActiveStep(step);
	};

	const { layouts, key: stepKey } = filteredSteps[activeStep];
	if (!layouts) {
		return null;
	}

	const currentStepValue = userSelectedValues && userSelectedValues[stepKey];
	const selectedValue = appStore.stepItemsLookup.get(stepKey)?.get(currentStepValue);
	const stepsLength = filteredSteps.length - 1;

	let isAcceptDisabled = true;
	if (activeStep === stepsLength) {
		if (allAcceptanceChecked !== undefined) {
			isAcceptDisabled = !allAcceptanceChecked;
		} else {
			isAcceptDisabled = false;
		}
	}

	const layoutProps = {
		...props,
		steps: filteredSteps,
		activeStep,
		handleStep,
		selectedValue,
		isAcceptDisabled,
		stepsLength,
		stepKey,
		setActiveStep,
		currentStepValue
	};

	return (
		<Grid container data-selector={`attestation-stepper-grid-container`} key={`attestation-stepper-grid-container`}>
			<StepLayout {...layoutProps} layout={layouts.header} type="header" />
			<StepLayout {...layoutProps} layout={layouts.body} type="body" />
			<StepLayout {...layoutProps} layout={layouts.footer} type="footer" />
		</Grid>
	);
};

interface IStepLayoutProps extends IMuiStepperProps {
	type: 'header' | 'body' | 'footer';
	layout: ILayout<TGridStepHeaderComponentProps | TGridStepBodyComponentProps | TGridStepFooterComponentProps>;
	activeStep: number;
	setActiveStep: React.Dispatch<React.SetStateAction<number>>;
	handleStep: (step: number) => void;
	selectedValue: IItemBasicProps;
	isAcceptDisabled: boolean;
	stepsLength: number;
	stepKey: TAttestationKey;
	currentStepValue: string;
}

const StepLayout: React.FC<IStepLayoutProps> = ({
	type,
	layout,
	steps,
	handleAcceptClick,
	handleRejectClick,
	checkboxOnChange,
	setUserSelectedValues,
	userSelectedValues,
	activeStep,
	setActiveStep,
	handleStep,
	selectedValue,
	isAcceptDisabled,
	stepsLength,
	stepKey,
	currentStepValue,
}) => {
	const appStore = useStores<AppStore>('appStore');

	const muiStepperStyles = useMuiStyles('muiStepper') as IMuiStepperStyles;
	if (!layout) {
		return null;
	}
	return (
		<Grid
			container
			data-selector={`attestation-stepper-${type}-grid-container`}
			key={`attestation-stepper-${type}-grid-container`}
			{...layout.options}
		>
			{layout.grid.map(({ components, options }, index) => {
				if (components?.length > 0) {
					return (
						<Grid
							data-selector={`attestation-stepper-${type}-grid-item`}
							key={`attestation-stepper-${type}-grid-item-${index}`}
							{...options}
						>
							{components.map(({ key, config }) => {
								let component = null;
								switch (key) {
									case 'stepper':
										component = (
											<MuiStepperSteps
												key={`${key}-component`}
												config={config}
												activeStep={activeStep}
												steps={steps}
												handleStep={handleStep}
												userSelectedValues={userSelectedValues}
											/>
										);
										break;
									case 'title':
										component = (
											<MuiTextDisplay
												key={`title-${key}-component`}
												text={config.title}
												options={config.options}
											/>
										);
										break;
									case 'description':
										component = (
											<MuiTextDisplay
												key={`attestation-${key}-component`}
												text={config.description}
												options={config.options}
											/>
										);
										break;
									case 'selection':
										if (!steps[activeStep].typeOptions?.selection) {
											break;
										}
										component = (
											<MuiSelection
												key={`attestation-${key}-component`}
												activeStepKey={stepKey}
												setUserSelectedValues={setUserSelectedValues}
												selectedValue={selectedValue}
												{...steps[activeStep].typeOptions.selection}
											/>
										);
										break;
									case 'checkboxes': {
										if (config && Array.isArray(config)) {
											component = config.map((checkboxConfig) => {
												return (
													<MuiCheckbox
														key={`attestation-${key}-component-${checkboxConfig.id}`}
														config={checkboxConfig}
														onChange={checkboxOnChange}
													/>
												);
											});
										}
										break;
									}
									case 'actions':
										component = (
											<MuiActions
												key={`attestation-${key}-component`}
												config={config}
												activeStep={activeStep}
												stepsLength={stepsLength}
												isAcceptDisabled={isAcceptDisabled}
												currentStepValue={currentStepValue}
												setActiveStep={setActiveStep}
												handleAcceptClick={handleAcceptClick}
												handleRejectClick={handleRejectClick}
											/>
										);
										break;
									case 'links':
										component = (
											<Links
												key={`links-${key}-component`}
												links={config as ILinkProps[]}
												styles={muiStepperStyles?.muiLink?.link?.styles}
											/>
										);
										break;
									case 'disclaimers':
										component = (
											<Disclaimers
												key={`disclaimers-${key}-component`}
												disclaimers={config as query.disclaimers.GlobalDisclaimerStoreValue[]}
												styles={muiStepperStyles?.muiDisclaimer?.typography?.styles}
											/>
										);
										break;
									case 'languageSelector':
										{
											const { languageSelectorStore } = appStore;
											if (languageSelectorStore) {
												component = (
													<MuiDropdown
														key={`attestation-${key}-component`}
														{...languageSelectorStore.props}
													/>
												);
											}
										}
										break;
									case 'closeButton':
										component = (
											<CloseButton
												key={`close-${key}`}
												closeButton={config}
												styles={muiStepperStyles?.closeButton}
											/>
										);
										break;
									default:
										break;
								}
								return component;
							})}
						</Grid>
					);
				}
			})}
		</Grid>
	);
};
