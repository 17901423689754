import { models, stores } from '@kurtosys/app-start';
import { EventTypes } from '@kurtosys/event-manager/dist/models/EventTypes.js';
import { common, query } from '@kurtosys/ksys-app-template';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { IStorageHelperConfigurationOptions } from '@kurtosys/types/helpers/StorageHelper/IStorageHelperConfigurationOptions.js';
import { TStorageType } from '@kurtosys/types/helpers/StorageHelper/TStorageType.js';
import { action, computed, makeObservable, observable } from 'mobx';

import { LIBRARY_COMPONENTS_CONFIGURATION } from '../../../configuration/libraryComponentsConfiguration.js';
import { MockData } from '../../../configuration/mockData/index.js';
import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { IAttestation, IInputs } from '../../../models/index.js';
import { ICloseButtonConfig } from '../../CloseButton/models/ICloseButtonConfig.js';
import { IDisclaimerRefConfig } from '../../Disclaimers/models/IDisclaimerRefConfig.js';
import { DisclaimerStore } from '../../Disclaimers/stores/DisclaimerStore.js';
import { ILinkProps } from '../../Links/models/ILinkProps.js';
import { ILinkRefConfig } from '../../Links/models/ILinkRefConfig.js';
import { LinkStore } from '../../Links/stores/LinkStore.js';
import { DropdownStore } from '../../MuiDropdown/stores/DropdownStore.js';
import { IMuiHeaderConfig } from '../../MuiHeader/models/IMuiHeaderConfig.js';
import { IMuiHeaderLogoProps, IMuiHeaderTextProps } from '../../MuiHeader/models/IMuiHeaderProps.js';
import { TGridHeaderComponentsConfig } from '../../MuiHeader/models/TGridHeaderComponentsConfig.js';
import { TDisclaimers as THeaderDisclaimers } from '../../MuiHeader/models/TGridHeaderComponentsConfig.js';
import { TGridHeaderComponentsProps } from '../../MuiHeader/models/TGridHeaderComponentsProps.js';
import { ICheckbox } from '../../MuiStepper/models/interfaces/ICheckbox.js';
import { IItem } from '../../MuiStepper/models/interfaces/IItem.js';
import { IItemBasicProps } from '../../MuiStepper/models/interfaces/IItemProps.js';
import { IMuiStepperProps } from '../../MuiStepper/models/interfaces/IMuiStepperProps.js';
import { ISelection } from '../../MuiStepper/models/interfaces/ISelection.js';
import { IStepConfig } from '../../MuiStepper/models/interfaces/IStepConfig.js';
import { IStepIcons } from '../../MuiStepper/models/interfaces/IStepIcons.js';
import { IStepProps } from '../../MuiStepper/models/interfaces/IStepProps.js';
import { EAttestationKeys, TAttestationKey } from '../../MuiStepper/models/types/TAttestationKey.js';
import {
	TDisclaimers as TStepperDisclaimers,
	TGridStepBodyComponentConfig,
	TGridStepFooterComponentConfig,
	TGridStepHeaderComponentConfig,
	TLinks,
} from '../../MuiStepper/models/types/TGridStepComponentConfig.js';
import {
	TGridStepBodyComponentProps,
	TGridStepFooterComponentProps,
	TGridStepHeaderComponentProps,
} from '../../MuiStepper/models/types/TGridStepComponentProps.js';
import { WHITE_LABEL_LAYOUT } from '../../MuiStepper/models/WHITE_LABEL_LAYOUT.js';
import { Feature } from '../../shared/Feature.js';
import { IAcceptanceCheckboxes } from '../models/IAcceptanceCheckboxes.js';
import { IAppComponents } from '../models/IAppComponents.js';
import { IAutoAttest } from '../models/IAutoAttest.js';
import { IConditionalConfig } from '../models/IConditionalConfig.js';
import { IFeatures } from '../models/IFeatures.js';
import { IGrid } from '../models/IGrid.js';
import { IGeolocation, TStorageSource } from '../models/IInitialSelection.js';
import { ILayout } from '../models/ILayout.js';
import { ILayoutsConfig } from '../models/ILayoutsConfig.js';
import { IMuiDialogProps } from '../models/IMuiDialogProps.js';
import { IRedirects } from '../models/IRedirects.js';
import { IOptInCheckbox, IStorage } from '../models/IStorage.js';

import { AcceptanceStore } from './AcceptanceStore.js';
import { DisableAttestStore } from './DisableAttestStore.js';
type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;

/* [Component: appStoreComponentImport] */

let devConfig: models.IAppDevelopmentConfig = {
	applicationClientConfigurationIds: null,
	applicationClientConfigurations: null,
	authentication: null,
	configuration: null,
	styles: null,
	libraryComponentsConfiguration: LIBRARY_COMPONENTS_CONFIGURATION,
};

if (process.env.NODE_ENV !== 'production') {
	devConfig = {
		...devConfig,
		applicationClientConfigurationIds: async () => {
			const { APPLICATION_CLIENT_CONFIGURATION_IDS } = await import(
				'../../../configuration/development.applicationClientConfigurationIds.js'
			);
			return APPLICATION_CLIENT_CONFIGURATION_IDS;
		},
		applicationClientConfigurations: async () => {
			const { APPLICATION_CLIENT_CONFIGURATIONS } = await import(
				'../../../configuration/development.applicationClientConfigurations.js'
			);
			return APPLICATION_CLIENT_CONFIGURATIONS;
		},
		authentication: async () => {
			const { AUTHENTICATION } = await import('../../../configuration/development.authentication.js');
			return AUTHENTICATION;
		},
		configuration: async () => {
			const { CONFIGURATION } = await import('../../../configuration/development.config.js');
			return CONFIGURATION;
		},
		styles: async () => {
			const { STYLES } = await import('../../../configuration/development.styles.js');
			return STYLES;
		},
	};
}

const { deepMergeObjects } = common.commonUtils;

export type TUserSelections = Partial<Record<TAttestationKey, string>>;

type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;

export class AppStore extends stores.base.AppStoreBase<IConfiguration, IComponentStyles> {
	static EVENT_BUS_KEY: EventTypes = EventTypes.GLOBAL_INPUTS_CHANGE;
	static storageKey = 'ksys-attestation';
	url: URL;
	storageHelper: common.helpers.StorageHelper;
	previousRedirectStorageHelper = new common.helpers.StorageHelper({ type: 'SESSION' });
	hasAcceptanceCheckboxes = false;
	@observable userCountryCode: string | undefined;
	// Whether the Attestation should render
	@observable showAttestation = false;
	@observable hasAttested = false;
	// This will store the values that the user selected per step
	// The key should line up with the step then the selected value as the value
	// The storage will populate this to allow for prefilling of previously selected values
	// {
	//   country: 'de',
	//   investor: 'intermediate'
	// }
	@observable.ref userSelectedValues: TUserSelections = {};
	@observable.ref initialUserSelectedValues: TUserSelections = {};
	acceptanceCheckboxesIds: string[] = [];
	// This will indicate if the user has clicked the opt in checkbox, at storage this will be checked before saving.
	// Default to true as the opt in configuration is optional. Once the checkbox props is orchestrated from config this will be set false
	// Not an observable as this should not trigger any reactions
	userOptIn = true;
	// With multiple attestations on, the force redirect feature will be turned off and a link to the latest attestation will render in the header
	// Thus we will check if current site matches any of the attestations but only work with the latest
	@observable.ref previousAttestations: IAttestation[] = [];
	// Used to track if attestation has redirected previously for the session when using redirectByPreviousAttestation === once
	redirectStatusKey = 'ksys-attestation-previously-redirected';

	constructor(
		element: HTMLElement,
		url: string,
		storeContext: TStoreContext,
		manifest: IManifest,
		mockData?: MockData,
	) {
		super(element, url, storeContext, manifest, Feature, devConfig, mockData);
		this.url = new URL(url);
		makeObservable(this);
	}

	get hasData(): boolean {
		// TODO: Each Application should put custom show logic here: "return this.storeContext.get<Store>(storeKey).hasData;"
		return true;
	}

	@action
	contextsDidUpdateAfter = async () => {
		// TODO: Each Application should put custom logic here to handle changes to the data context
	};

	get components(): IAppComponents {
		return {};
	}

	@computed
	get muiDialogProps(): IMuiDialogProps {
		return this.configuration.components?.muiDialog || {};
	}

	@observable.ref private _redirectHelper: common.helpers.RedirectHelper | null = null;

	@computed
	get redirectHelper(): common.helpers.RedirectHelper {
		return this._redirectHelper;
	}

	@action
	initializeRedirectHelper = () => {
		const translationStore = this.translationStore ?? this.storeContext.get('translationStore');
		if (translationStore.translationHelper) {
			const { translationHelper } = translationStore;
			this._redirectHelper = new common.helpers.RedirectHelper({}, window.location.toString(), undefined, {
				translationHelper,
			});
		}
	};

	@computed
	get muiStepperLayoutProps(): IMuiStepperProps {
		const { steps, defaultLayouts, closeButton } = this.configuration.components.muiStepper;
		if (steps) {
			const stepsProps: IStepProps[] = [];
			for (const step of steps) {
				const stepProps: IStepProps = {
					key: step.key,
					stepIcons: {},
					layouts: {},
					closeButton,
				};
				const { layouts: stepLayoutsConfig, stepLabelQuery, conditional } = step;
				let layoutsConfig: ILayoutsConfig = WHITE_LABEL_LAYOUT;
				if (defaultLayouts || stepLayoutsConfig) {
					layoutsConfig = { ...defaultLayouts, ...stepLayoutsConfig };
				}

				stepProps.conditional = conditional;
				stepProps.stepIcons = {
					default: {
						src:
							this.getQueryValue(step.stepIcons?.default?.srcQuery) ||
							step.stepIcons?.default?.src ||
							this.defaultStepIcons?.default?.src,
						alt:
							this.getQueryValue(step.stepIcons?.default?.altQuery) ||
							step.stepIcons?.default?.alt ||
							this.defaultStepIcons?.default?.alt,
					},
					active: {
						src:
							this.getQueryValue(step.stepIcons?.active?.srcQuery) ||
							step.stepIcons?.active?.src ||
							this.defaultStepIcons?.active?.src,
						alt:
							this.getQueryValue(step.stepIcons?.active?.altQuery) ||
							step.stepIcons?.active?.alt ||
							this.defaultStepIcons?.active?.alt,
					},
					complete: {
						src: this.defaultStepIcons?.complete?.src,
						alt: this.defaultStepIcons?.complete?.alt,
					},
				};

				stepProps.stepLabel = this.getQueryValue(stepLabelQuery);

				if (layoutsConfig) {
					if (layoutsConfig.header) {
						stepProps.layouts.header = this.getStepLayout(step, layoutsConfig.header, stepProps);
					}
					if (layoutsConfig.body) {
						stepProps.layouts.body = this.getStepLayout(step, layoutsConfig.body, stepProps);
					}
					if (layoutsConfig.footer) {
						stepProps.layouts.footer = this.getStepLayout(step, layoutsConfig.footer, stepProps);
					}
				}
				stepsProps.push(stepProps);
			}
			// Need to set the step to the first step that does not have a value preselected from the storage or inputs
			let nextActiveStep = 0;
			for (let i = 0; i < stepsProps.length; i++) {
				const { key } = stepsProps[i];
				if (!this.userSelectedValues[key]) {
					nextActiveStep = i;
					break;
				}
			}
			const muiStepperLayoutProps: IMuiStepperProps = {
				steps: stepsProps,
				setUserSelectedValues: this.setUserSelectedValuesFromControls,
				userSelectedValues: this.userSelectedValues,
				defaultActiveStep: nextActiveStep,
			};

			muiStepperLayoutProps.handleAcceptClick = this.handleAcceptClick;
			muiStepperLayoutProps.handleRejectClick = this.handleRejectClick;
			muiStepperLayoutProps.checkboxOnChange = this.handleCheckboxClick;
			if (this.hasAcceptanceCheckboxes) {
				muiStepperLayoutProps.allAcceptanceChecked = this.acceptanceStore.allAcceptanceChecked;
			}

			return muiStepperLayoutProps;
		}
	}

	getStepLayout = (
		step: IStepConfig,
		layoutConfig: ILayout<
			TGridStepHeaderComponentConfig | TGridStepBodyComponentConfig | TGridStepFooterComponentConfig
		>,
		stepProps: IStepProps,
	) => {
		const { alternativeLabel, orientation, actions, showNavigationActions } =
			this.configuration.components.muiStepper;
		const {
			titleQuery,
			descriptionQuery,
			disclaimers,
			typeOptions: typeOptionsConfig,
			type,
			links,
			checkboxes,
		} = step;
		const layout = {
			options: layoutConfig.options,
			grid: [],
		};
		for (const gridItemConfig of layoutConfig.grid) {
			if (gridItemConfig.components) {
				const gridItemProps: IGrid<
					TGridStepHeaderComponentProps | TGridStepBodyComponentProps | TGridStepFooterComponentProps
				> = {
					options: gridItemConfig.options,
					components: [],
				};
				for (const component of gridItemConfig.components) {
					const gridItemComponentProps:
						| TGridStepHeaderComponentProps
						| TGridStepBodyComponentProps
						| TGridStepFooterComponentProps = {
						key: component.key,
					};
					// Stepper Logic
					if (component.key === 'stepper') {
						const rawComponentConfig = {
							alternativeLabel,
							orientation,
						};
						const rawGridComponentConfig = { ...component.config };
						const mergedConfig = deepMergeObjects(rawComponentConfig, rawGridComponentConfig);

						gridItemComponentProps.config = {
							...mergedConfig,
							orientation: mergedConfig.orientation,
							alternativeLabel: mergedConfig.alternativeLabel,
						};
					}
					// Title Logic
					if (component.key === 'title') {
						const rawComponentConfig = { titleQuery };
						const rawGridComponentConfig = { ...component.config };
						const mergedConfig = deepMergeObjects(rawComponentConfig, rawGridComponentConfig);

						gridItemComponentProps.config = {
							...mergedConfig,
							title: this.getQueryValue(mergedConfig.titleQuery),
						};
					}
					// Description Logic
					if (component.key === 'description') {
						const rawComponentConfig = { descriptionQuery };
						const rawGridComponentConfig = { ...component.config };
						const mergedConfig = deepMergeObjects(rawComponentConfig, rawGridComponentConfig);

						gridItemComponentProps.config = {
							...mergedConfig,
							description: this.getQueryValue(mergedConfig.descriptionQuery),
						};
					}
					// Selection Logic
					if (component.key === 'selection') {
						if (type === 'acceptance') {
							// No logic required here
						}
						if (type === 'selection') {
							if (!typeOptionsConfig) {
								this.log('warning', {
									message:
										'MuiStepper: You need to define a "typeOptions" property on the step when type is "selection"',
								});
								continue;
							}

							const { selection: selectionConfig } = typeOptionsConfig;
							if (selectionConfig.items?.length > 0) {
								stepProps.typeOptions = {
									selection: {
										type: selectionConfig.type,
										typeOptions: { ...(selectionConfig.typeOptions ?? {}) },
										items: [],
										gridContainerOptions: {
											...(selectionConfig.gridContainerOptions ?? {}),
										},
										gridOptions: { ...(selectionConfig.gridOptions ?? {}) },
									},
								};
								for (const selectionItemConfig of selectionConfig.items) {
									const selectionItemProps: IItem = { ...selectionItemConfig };

									// Copy the global grid options into the item if it's not in the config
									if (!selectionItemProps.gridContainerOptions) {
										selectionItemProps.gridContainerOptions = {
											...(selectionConfig.gridContainerOptions ?? {}),
										};
									}

									this.setSelectionItemProps(
										selectionItemProps,
										selectionItemConfig,
										selectionConfig,
									);

									if (selectionItemConfig.isGroup) {
										if (!selectionItemConfig.items || selectionItemConfig.items?.length === 0) {
											this.log('warning', {
												message:
													'MuiStepper: You need to define an "items" property when isGroup is true',
											});
											continue;
										}

										selectionItemProps.items = [];
										for (const groupItemConfig of selectionItemConfig.items) {
											const groupItemProps: Omit<
												IItem,
												'isGroup' | 'items' | 'gridContainerOptions'
											> = {
												...groupItemConfig,
											};

											// Copy the global grid options into the item if it's not in the config
											if (!groupItemProps.gridOptions) {
												groupItemProps.gridOptions = {
													...(selectionItemProps.gridOptions ?? {}),
												};
											}
											this.setSelectionItemProps(
												groupItemProps,
												groupItemConfig,
												selectionConfig,
											);
											selectionItemProps.items.push(groupItemProps);
										}
									}
									stepProps.typeOptions.selection.items.push(selectionItemProps);
								}
							}
						}
					}
					// Checkbox Logic
					if (component.key === 'checkboxes') {
						// Resolve the acceptance checkboxes and optInCheckbox props here
						const rawComponentConfig = checkboxes;
						const rawGridComponentConfig = component.config ?? [];

						const mergedConfig: ICheckbox[] = [];
						// TODO: merge the rawComponentConfig and rawGridComponentConfig
						if (rawComponentConfig) {
							mergedConfig.push(...rawComponentConfig);
						}
						if (rawGridComponentConfig) {
							mergedConfig.push(...rawGridComponentConfig);
						}
						if (step.key === 'acceptance') {
							// Get the acceptance and opt-in checkboxes
							const acceptanceCheckboxesConfiguration = this.acceptanceCheckboxesConfiguration;
							const checkboxes: ICheckbox[] = [];

							// Acceptance checkboxes
							if (acceptanceCheckboxesConfiguration) {
								// orchestrate the acceptance config to the checkbox config
								const acceptanceCheckboxes = this.getAcceptanceCheckboxes(
									acceptanceCheckboxesConfiguration,
								);
								checkboxes.push(...acceptanceCheckboxes);
							}

							// Opt-in Checkbox
							const optInCheckbox = this.optInCheckboxConfiguration;
							if (optInCheckbox?.enabled) {
								// orchestrate the opt-in config to the checkbox config
								checkboxes.push({
									id: 'opt-in',
									labelQuery: optInCheckbox.label,
									required: false,
								});
							}
							mergedConfig.push(...checkboxes);
						}

						if (mergedConfig.length > 0) {
							gridItemComponentProps.config = mergedConfig.map((checkboxConfig) => {
								let label = checkboxConfig.label;
								if (checkboxConfig.labelQuery) {
									label = this.getQueryValue(checkboxConfig.labelQuery);
								}
								return {
									...checkboxConfig,
									label,
								};
							});
						}
					}
					// Action Logic
					if (component.key === 'actions') {
						if (actions) {
							const rawComponentConfig = { ...actions, showNavigationActions };
							const rawGridComponentConfig = { ...component.config };
							const mergedConfig = deepMergeObjects(rawComponentConfig, rawGridComponentConfig);

							gridItemComponentProps.config = { ...mergedConfig };

							for (const key in mergedConfig) {
								if (key !== 'align' && key !== 'displayOrder') {
									gridItemComponentProps.config[key] = this.getQueryValue(mergedConfig[key]);
								}
							}
						}
					}
					// Links Logic
					if (component.key === 'links') {
						const linksProps = this.getRefElementProps<ILinkRefConfig, TLinks>(
							links,
							component,
							this.linkStore.getLinks,
						);
						gridItemComponentProps.config = linksProps;
					}
					// Disclaimer Logic
					if (component.key === 'disclaimers') {
						const disclaimerValues = this.getRefElementProps<IDisclaimerRefConfig, TStepperDisclaimers>(
							disclaimers,
							component,
							this.disclaimerStore.getDisclaimers,
						);
						gridItemComponentProps.config = disclaimerValues;
					}

					// Close button Logic
					if (component.key === 'closeButton') {
						if (this.showMode === 'change') {
							gridItemComponentProps.config = stepProps.closeButton;
							if (
								gridItemComponentProps.config &&
								(gridItemComponentProps.config as ICloseButtonConfig).icon
							) {
								(gridItemComponentProps.config as ICloseButtonConfig).icon.src =
									this.getQueryValue(
										(gridItemComponentProps.config as ICloseButtonConfig).icon?.srcQuery,
									) || (gridItemComponentProps.config as ICloseButtonConfig).icon?.src;
								(gridItemComponentProps.config as ICloseButtonConfig).icon.alt =
									this.getQueryValue(
										(gridItemComponentProps.config as ICloseButtonConfig).icon?.altQuery,
									) || (gridItemComponentProps.config as ICloseButtonConfig).icon?.alt;
							}
						} else {
							continue;
						}
					}

					gridItemProps.components.push(gridItemComponentProps);
				}
				layout.grid.push(gridItemProps);
			}
		}
		return layout;
	};

	/**
	 * Sets the properties of a selection item based on its configuration and the selection's configuration.
	 * @param {IItem} selectionItemProps - The selection item's properties to be set.
	 * @param {IItem} selectionItemConfig - The selection item's configuration.
	 * @param {ISelection} selectionConfig - The selection's configuration.
	 * @returns {void}
	 */
	setSelectionItemProps = (selectionItemProps: IItem, selectionItemConfig: IItem, selectionConfig: ISelection) => {
		selectionItemProps.label = this.getQueryValue(selectionItemConfig.labelQuery) || selectionItemConfig.label;
		selectionItemProps.buttonLabel =
			this.getQueryValue(selectionItemConfig.buttonLabelQuery) ||
			selectionItemConfig.buttonLabel ||
			selectionConfig.typeOptions?.card?.defaultButtonLabel ||
			'Select';
		selectionItemProps.iconSrc =
			this.getQueryValue(selectionItemConfig.iconSrcQuery) || selectionItemConfig.iconSrc;
		selectionItemProps.iconAlt =
			this.getQueryValue(selectionItemConfig.iconAltQuery) || selectionItemConfig.iconAlt;
		// default to label if no value is provided
		selectionItemProps.value =
			this.getQueryValue(selectionItemConfig.valueQuery) || selectionItemConfig.value || selectionItemProps.label;
		selectionItemProps.description =
			this.getQueryValue(selectionItemConfig.descriptionQuery) || selectionItemConfig.description;
	};

	@computed
	get stepKeys() {
		const keys = [];
		if (this.muiStepperLayoutProps?.steps) {
			keys.push(...this.muiStepperLayoutProps.steps.map((step) => step.key));
		}
		if (this.languageSelectorStore?.props?.items) {
			keys.push('language');
		}
		return keys;
	}

	getStepKeysWithout(...keys: ('acceptance' | 'language')[]) {
		return this.stepKeys.filter((key) => keys.find((k) => k === key) === undefined);
	}

	/**
	 * Returns a Map of step items for each step in the stepper layout.
	 * The Map is keyed by the step key and contains a Map of the basic item props.
	 * If an item is a group, the Map contains the basic props of each item in the group. (flat structure)
	 * @returns {Map<string, Map<string, IItemBasicProps>>} A Map of step items for each step in the stepper layout.
	 */
	@computed
	get stepItemsLookup() {
		const stepLookup = new Map<string, Map<string, IItemBasicProps>>();
		for (const step of this.muiStepperLayoutProps.steps) {
			if (step.typeOptions?.selection?.items) {
				const itemMap = new Map<string, IItemBasicProps>();
				for (const item of step.typeOptions.selection.items) {
					if (item.isGroup) {
						for (const groupItem of item.items) {
							// use value if present, otherwise use label
							itemMap.set(groupItem.value || groupItem.label, {
								label: groupItem.label,
								value: groupItem.value,
								iconAlt: groupItem.iconAlt,
								iconSrc: groupItem.iconSrc,
								description: groupItem.description,
								conditional: groupItem.conditional,
								// alias: groupItem.alias,
							});
						}
					} else {
						itemMap.set(item.value || item.label, {
							label: item.label,
							value: item.value,
							iconAlt: item.iconAlt,
							iconSrc: item.iconSrc,
							description: item.description,
							conditional: item.conditional,
							// alias: item.alias,
						});
					}
				}
				stepLookup.set(step.key, itemMap);
			}
			if (this.languageSelectorStore?.props?.items) {
				const itemMap = new Map<string, IItemBasicProps>();
				for (const item of this.languageSelectorStore.props.items) {
					if (this.isConditionValid(item.conditional)) {
						const isDefault = item.defaultConditional && this.isConditionValid(item.defaultConditional);
						itemMap.set(item.value || item.label, {
							label: item.label,
							value: item.value,
							isDefault: isDefault,
							alias: item.alias,
							conditional: item.conditional,
						});
					}
				}
				stepLookup.set('language', itemMap);
			}
		}
		return stepLookup;
	}

	getRefElementProps<TElement extends { key }, TComponent extends { config?; refKeys? }>(
		element: TElement[],
		component: TComponent,
		getFn: (config: TComponent['config']) => any,
	) {
		const rawComponentConfig = element;
		const rawGridComponentConfig = component.config;
		const mergedConfig =
			rawGridComponentConfig ??
			rawComponentConfig?.filter(
				(config) => component.refKeys === undefined || component.refKeys.includes(config.key),
			) ??
			[];
		const linksProps = getFn(mergedConfig);
		return linksProps;
	}

	applyInstanceToConditional(instance: any, conditional?: IConditionalConfig) {
		if (conditional === undefined || conditional.conditions === undefined) {
			return true;
		}
		const conditionalHelper = new common.helpers.ConditionalHelper({ queryClass: query.Query }, conditional);
		return conditionalHelper.matchesWithOptions({
			instance,
			executionOptions: this.queryStore.executionOptions,
		});
	}

	@action
	isConditionValid(conditional: IConditionalConfig | undefined): boolean {
		return this.applyInstanceToConditional(this.userSelectedValues, conditional);
	}

	getAcceptanceCheckboxes(acceptanceCheckboxesConfiguration: IAcceptanceCheckboxes[]): ICheckbox[] {
		return acceptanceCheckboxesConfiguration.map((config) => {
			this.acceptanceCheckboxesIds.push(config.key);
			return {
				id: config.key,
				labelQuery: config.text,
				required: true,
				isDefaultSelected: this.acceptanceStore.getAcceptanceState(config.key),
			} as ICheckbox;
		});
	}

	@computed
	get muiHeaderConfig(): IMuiHeaderConfig {
		const { muiHeader } = this.configuration.components;
		return muiHeader || {};
	}

	@computed
	get muiHeaderLayoutProps(): ILayout<TGridHeaderComponentsProps> {
		const { layouts } = this.muiHeaderConfig;
		if (layouts?.grid) {
			const muiHeaderConfig: ILayout<TGridHeaderComponentsConfig> = layouts;
			const layoutProps: ILayout<TGridHeaderComponentsProps> = {
				grid: [],
			};
			for (const gridItemConfig of muiHeaderConfig.grid) {
				const gridItemProps: IGrid<TGridHeaderComponentsProps> = {
					options: gridItemConfig.options,
					components: [],
				};
				for (const component of gridItemConfig.components) {
					const gridComponent = {
						key: component.key,
						config: {},
					} as TGridHeaderComponentsProps;
					if (component.key === 'title' || component.key === 'subTitle') {
						const rawComponentConfig = this.muiHeaderConfig[component.key];
						const rawGridComponentConfig = component.config || {};
						const mergedConfig = deepMergeObjects(rawComponentConfig, rawGridComponentConfig);
						gridComponent.config = deepMergeObjects(gridComponent.config, mergedConfig);

						(gridComponent.config as IMuiHeaderTextProps).text =
							this.getQueryValue(mergedConfig.textQuery) || component.config.text;
					}
					if (component.key === 'logo') {
						const rawComponentConfig = this.muiHeaderConfig[component.key];
						const rawGridComponentConfig = component.config || {};
						const mergedConfig = deepMergeObjects(rawComponentConfig, rawGridComponentConfig);
						gridComponent.config = deepMergeObjects(gridComponent.config, mergedConfig);

						(gridComponent.config as IMuiHeaderLogoProps).src =
							this.getQueryValue(mergedConfig.srcQuery) || component.config.src;
						(gridComponent.config as IMuiHeaderLogoProps).alt =
							this.getQueryValue(mergedConfig.altQuery) || component.config.alt;
					}
					if (component.key === 'disclaimers') {
						const disclaimers = this.getRefElementProps<IDisclaimerRefConfig, THeaderDisclaimers>(
							this.muiHeaderConfig[component.key],
							component,
							this.disclaimerStore.getDisclaimers,
						);
						gridComponent.config = disclaimers;
					}
					if (component.key === 'previousAttestedLink') {
						if (this.featuresConfiguration) {
							if (
								!this.hasAttested &&
								this.previousAttestations.length > 0 &&
								(this.allowMultipleAttestations ||
									(this.showMode === 'validation' && this.redirectMode === 'disabled'))
							) {
								gridComponent.config = this.previousAttestationLinkProps;
							}
						}
					}
					if (component.key === 'closeButton') {
						if (this.featuresConfiguration) {
							if (this.showMode === 'change') {
								gridComponent.config = this.muiHeaderConfig[component.key] || {};

								if (gridComponent.config && (gridComponent.config as ICloseButtonConfig).icon) {
									(gridComponent.config as ICloseButtonConfig).icon.src =
										this.getQueryValue(
											(gridComponent.config as ICloseButtonConfig).icon?.srcQuery,
										) || (gridComponent.config as ICloseButtonConfig).icon?.src;
									(gridComponent.config as ICloseButtonConfig).icon.alt =
										this.getQueryValue(
											(gridComponent.config as ICloseButtonConfig).icon?.altQuery,
										) || (gridComponent.config as ICloseButtonConfig).icon?.alt;
								}
							}
						}
					}
					if (Object.keys(gridComponent.config).length > 0) {
						gridItemProps.components.push(gridComponent);
					}
				}
				if (gridItemProps.components?.length > 0) {
					layoutProps.grid.push(gridItemProps);
				}
			}
			return layoutProps;
		}
	}

	@computed
	get previousAttestationLinkProps(): ILinkProps {
		const previousRedirectLinkMessage = this.featuresConfiguration?.previousRedirectLinkMessage;
		let text = '';
		if (previousRedirectLinkMessage) {
			text = this.getQueryValue(previousRedirectLinkMessage);
		}
		let href = '';
		let target;
		if (this.redirectsConfiguration) {
			const { accepted } = this.redirectsConfiguration;
			if (accepted.options?.target === '_blank') {
				target = '_blank';
			}
			if (this.previousAttestations[0]?.values) {
				// The latest attestation will be the first in the array due to the sortByDate
				const previousAttestationValues = this.previousAttestations[0].values;
				href = this.redirectHelper.getRedirectPath(accepted, previousAttestationValues);
			}
		}
		const linkProps: ILinkProps = {
			href,
			text,
		};
		if (target) {
			linkProps.target = target;
		}
		return linkProps;
	}

	@computed
	get defaultStepIcons(): IStepIcons | null {
		const stepIconsConfig = this.configuration.components?.muiStepper?.stepIcons;

		if (stepIconsConfig) {
			return {
				default: {
					src: this.getQueryValue(stepIconsConfig?.default?.srcQuery) || stepIconsConfig?.default?.src,
					alt: this.getQueryValue(stepIconsConfig?.default?.altQuery) || stepIconsConfig?.default?.alt,
				},
				active: {
					src: this.getQueryValue(stepIconsConfig?.active?.srcQuery) || stepIconsConfig?.active?.src,
					alt: this.getQueryValue(stepIconsConfig?.active?.altQuery) || stepIconsConfig?.active?.alt,
				},
				complete: {
					src: this.getQueryValue(stepIconsConfig?.complete?.srcQuery) || stepIconsConfig?.complete?.src,
					alt: this.getQueryValue(stepIconsConfig?.complete?.altQuery) || stepIconsConfig?.complete?.alt,
				},
			};
		}

		return null;
	}

	handleLanguageSelectorChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		this.setUserSelectedValuesFromControls('culture', value);
		await this.handleCultureChange(value);
	};

	async customInitializeBefore(): Promise<void> {
		const eventHandlers: Partial<Record<EventTypes, (customEvent: CustomEvent<any>) => Promise<void>>> = {};
		if (this.showMode === 'change') {
			eventHandlers[EventTypes.CHANGE_ATTESTATION] = async (event) => {
				this.setShowAttestation(true);
			};
		}
		this.eventBusStore.initialize(eventHandlers);
	}

	/**
	 * The window.__ksysUserCountry__ is updated by the KsysRequest class
	 * when the fetchUserCountry flag has been provided,
	 * the flag was supplied to GetApplicationAppConfig in the KurtosysApiStore.
	 */
	initializeUserCountryCode() {
		this.userCountryCode = (window as any)[common.constants.GLOBAL_USER_COUNTRY_KEY];
	}

	async customInitializeAfter() {
		// If the attestation is disabled then no additional processing is required
		if (this.disableAttestStore.attestationIsDisabled) {
			return;
		}

		if (this.storage) {
			const { type, obfuscated, expiry } = this.storage;
			const storageOptions: IStorageHelperConfigurationOptions = {
				type: type as TStorageType,
				obfuscated: obfuscated,
				expiry: expiry,
			};
			this.storageHelper = new common.helpers.StorageHelper(storageOptions);
		}
		this.initializeUserCountryCode();
		await this.getHasAttested();

		this.languageSelectorStore?.initializeDropdown(
			this.featuresConfiguration?.languageSelector,
			this.handleLanguageSelectorChange,
		);

		this.setUserSelectedValuesFromControls('culture', this.initialSelectedCulture);
		this.disclaimerStore.initialize();
		this.initializeRedirectHelper();
		this.linkStore.initialize();

		if (this.acceptanceCheckboxesConfiguration) {
			this.hasAcceptanceCheckboxes = true;
			this.acceptanceStore.initialize();
		}

		if (this.optInCheckboxConfiguration?.enabled) {
			this.setOptIn(false);
		}
		// Setting the initialUserSelectedValues to the userSelectedValues, so that dirty values can be reverted when closing the dialog
		this.initialUserSelectedValues = { ...this.userSelectedValues };
		let isRedirecting = false;
		const isAutoAttestValid = this.validateAutoAttestParameters();
		const performAutoAttestRedirect = this.autoAttestConfiguration?.performRedirect;
		if (
			await this.canRedirectAttestation(this.isAttemptingAutoAttest, isAutoAttestValid, performAutoAttestRedirect)
		) {
			isRedirecting = this.redirectAttestation(isAutoAttestValid, performAutoAttestRedirect);
		} else if (this.isAttemptingAutoAttest) {
			// We calling this method because autoAttest has updated the localStorage and this is to prevent
			// the app from showing when we have valid values but we not redirecting
			if (isAutoAttestValid && !this.hasAttested) {
				await this.getHasAttested();
			}
			this.cleanQueryStringParameters(this.autoAttestConfiguration.parameters);
		}
		this.setIsInitialized(!isRedirecting);
		this.setShowAttestation(this.canShowOnInit(isAutoAttestValid, isRedirecting));
	}

	@action
	setOptIn = (value: boolean) => {
		this.userOptIn = value;
	};

	redirect(type: 'accept' | 'reject' | 'previous') {
		if (this.redirectsConfiguration) {
			const { accepted, rejected } = this.redirectsConfiguration;
			const { inputs = {} } = this.appParamsHelper.values;
			const rawRedirectInputs = this.applyCultureAlias({ ...inputs, ...this.userSelectedValues });

			let redirectType;
			let redirectInputs = rawRedirectInputs;
			switch (type) {
				case 'accept':
					redirectType = accepted;
					break;
				case 'reject':
					redirectType = rejected;
					break;
				case 'previous': {
					// With multiple attestations enabled a link will render in the header to go back to the previous attestation
					if (!this.allowMultipleAttestations) {
						const previousAttestationValues = (this.previousAttestations[0].values || {}) as Record<
							string,
							string
						>;
						redirectType = accepted;
						redirectInputs = { ...rawRedirectInputs, ...previousAttestationValues };
					}
					break;
				}
			}
			if (redirectType) {
				if (redirectInputs.language) {
					redirectInputs.culture = redirectInputs.language;
				}
				this.redirectHelper.go(redirectType, this.applyCultureAlias(redirectInputs));
			}
		}
	}

	applyCultureAlias(selectedValues: Record<string, string>, targetCulture?: string) {
		const culture = targetCulture || selectedValues.culture || selectedValues.language;
		if (culture) {
			const languageItem = this.languageSelectorStore.dropdownProps.items.find((item) => item.value === culture);
			if (languageItem) {
				const { value, label, alias } = languageItem;
				return {
					...selectedValues,
					cultureAlias: alias || value || label,
				};
			}
		}
		return selectedValues;
	}

	redirectAttestation(isAutoAttestValid: boolean, performAutoAttestRedirect: boolean): boolean {
		if (this.previousAttestations.length === 1 || (isAutoAttestValid && performAutoAttestRedirect)) {
			const result = this.previousRedirectStorageHelper.setStorageValues(this.redirectStatusKey, { value: true });
			if (!result) {
				this.log('warning', {
					message:
						'AppStore.redirectToPreviousAttestation: Setting of redirect status key was not successful',
				});
			}
			// The auto redirect logic will populate the user selected values, thus preform the accepted redirect
			if (isAutoAttestValid && performAutoAttestRedirect) {
				this.redirect('accept');
			} else {
				this.redirect('previous');
			}
			// Return true to prevent the rest of the attestation from loading
			return true;
		}
		return false;
	}

	// Logic to force the attestation to redirect to previous attested values or via query string parameters
	async canRedirectAttestation(
		isAttemptingAutoAttest: boolean,
		isAutoAttestValid: boolean,
		performAutoAttestRedirect: boolean,
	): Promise<boolean> {
		// If multiple attestations are configured we will display the latest attested site as a link in the header
		if (this.allowMultipleAttestations) {
			this.log('warning', {
				message:
					'Multiple Attestations are allowed, thus forced redirection on previous attestation has been disabled',
				detail: this.featuresConfiguration,
			});
		}

		const shouldRedirectByPrevious =
			this.showMode === 'validation' &&
			this.redirectMode !== 'disabled' &&
			!isAttemptingAutoAttest &&
			!this.hasAttested &&
			this.previousAttestations.length === 1;

		if ((isAutoAttestValid && performAutoAttestRedirect) || shouldRedirectByPrevious) {
			if (this.redirectMode === 'once') {
				return !(await this.hasPreviouslyRedirected());
			}
			if (this.redirectMode === 'always') {
				return true;
			}
		}
		return false;
	}

	cleanQueryStringParameters = (parameters: string[] = []) => {
		parameters.forEach((param) => {
			this.url.searchParams.delete(param);
		});

		window.history.replaceState(null, '', this.url.toString());
	};

	@computed
	get isAttemptingAutoAttest(): boolean {
		if (this.autoAttestConfiguration?.parameters) {
			return this.autoAttestConfiguration.parameters.some((param) => this.url.searchParams.has(param));
		}
		return false;
	}

	/**
	 * This will validate to see if the provided auto attest string contains required inputs,
	 * Then it will validate to see if the input values correspond to allowed values, based on step selections,
	 * Then it will validate to see the culture matches language selector, falling back to embed input or first available language.
	 * @returns boolean
	 */
	@action
	validateAutoAttestParameters(): boolean {
		if (this.showMode === 'validation' && this.autoAttestConfiguration?.parameters) {
			const { parameters, inputSeparator = '|', keyValueSeparator = ':' } = this.autoAttestConfiguration;
			if (this.isAttemptingAutoAttest) {
				const autoAttestMapping: Record<string, string> = {};
				// loop over allowed parameters in reverse order making, thus making the parameters in front take precedence.
				// (If for any reason there are multiple provided in the url, and they contain duplicate input keys)
				for (const param of parameters.reverse()) {
					if (this.url.searchParams.has(param)) {
						const queryStringParameter = this.url.searchParams.get(param);
						for (const inputKeyValue of queryStringParameter.split(inputSeparator)) {
							const [inputKey, inputValue] = inputKeyValue.split(keyValueSeparator);
							if (inputKey && inputValue) {
								if (inputKey === 'culture') {
									const [language, country] = inputValue.split('-');
									if (language && country) {
										// autoAttestMapping['language'] = language.toLowerCase();
										autoAttestMapping[
											inputKey
										] = `${language.toLowerCase()}-${country.toUpperCase()}`;
									}
								} else {
									autoAttestMapping[inputKey] = inputValue.toLowerCase();
								}
							}
						}
					}
				}
				const validation = this.validateSelections(autoAttestMapping);
				if (validation.validSelections) {
					this.userSelectedValues = validation.validSelections;
					if (validation.valid) {
						this.setStorageValues();
						return true;
					} else {
						if (!validation.validSelections.culture && autoAttestMapping.culture) {
							this.setUserSelectedValuesFromControls('culture', autoAttestMapping.culture);
						}
					}
				}
			}
		}
		return false;
	}

	isSelectionValid = (key: TAttestationKey, value: string) => {
		let item: IItemBasicProps | undefined;
		if (key === 'culture') {
			return this.languageSelectorStore.conditionalItems.length > 0;
		} else {
			item = this.stepItemsLookup.get(key)?.get(value);
		}
		if (item) {
			if (item.conditional) {
				return this.isConditionValid(item.conditional);
			}
		}

		return false;
	};

	async hasPreviouslyRedirected(): Promise<boolean> {
		const hasRedirected = (await this.previousRedirectStorageHelper.getStorageValues(this.redirectStatusKey)) as {
			value: boolean;
		};
		if (hasRedirected) {
			return hasRedirected.value;
		}
		return false;
	}

	@action
	setIsInitialized = (value: boolean) => {
		this.isInitialized = value;
	};

	@action
	setShowAttestation = (value: boolean) => {
		this.showAttestation = value;
	};

	@computed
	get initialSelectedCulture() {
		const { inputs } = this.appParamsHelper.values;
		if (inputs.culture) {
			return inputs.culture;
		}
		return this.translationStore.defaultCulture;
	}

	canShowOnInit(isAutoAttestValid: boolean, isRedirecting: boolean) {
		if (isRedirecting) {
			return false;
		}
		if (this.showMode === 'change') {
			return false;
		}
		if (this.showMode === 'always') {
			return true;
		}
		if (this.showMode === 'validation') {
			if (this.hasAttested && this.getInput('mustpreload') !== 'true') {
				return false;
			}
			if (isAutoAttestValid) {
				return false;
			}
			return true;
		}
		return false;
	}

	@computed
	get featuresConfiguration(): IFeatures | undefined {
		return this.appComponentConfiguration?.features;
	}

	@computed
	get validationMode(): IFeatures['validationMode'] {
		return this.featuresConfiguration?.validationMode || 'passive';
	}

	@computed
	get showMode(): IFeatures['showMode'] {
		// Fallback to validationMode if it exists
		if (
			this.featuresConfiguration?.showMode === undefined &&
			this.featuresConfiguration?.validationMode !== undefined
		) {
			switch (this.validationMode) {
				case 'active':
					return 'validation';
				case 'passive':
				default:
					return 'change';
			}
		}
		return this.featuresConfiguration?.showMode || 'change';
	}

	@computed
	get allowMultipleAttestations() {
		if (this.featuresConfiguration?.allowMultipleAttestations !== undefined) {
			return this.featuresConfiguration.allowMultipleAttestations;
		}
		return false;
	}

	@computed
	get forcePrevious(): IRedirects['forcePrevious'] {
		if (this.allowMultipleAttestations) {
			return false;
		}
		if (this.featuresConfiguration?.redirects?.forcePrevious !== undefined) {
			return this.featuresConfiguration?.redirects?.forcePrevious;
		}
		return false;
	}

	@computed
	get redirectMode(): IFeatures['redirectMode'] {
		if (this.allowMultipleAttestations) {
			return 'disabled';
		}
		if (this.featuresConfiguration?.redirectMode === undefined) {
			const input = this.getInput('redirectByPreviousAttestation');
			if (input && ['true', 'false', 'once'].indexOf(input) >= 0) {
				switch (input) {
					case 'false':
						return 'disabled';
					case 'true':
						return 'always';
					case 'once':
						return 'once';
				}
			} else if (this.forcePrevious) {
				return 'always';
			}
		}
		return this.featuresConfiguration?.redirectMode || 'always';
	}

	@computed
	get storage(): IStorage {
		// Defaults as per requirements: https://kurtosys-prod-eng.atlassian.net/wiki/spaces/KA/pages/4206067877/Attest.+Feature+Storage
		const defaults: IStorage = {
			type: 'LOCAL',
			obfuscated: true,
			optInCheckbox: {
				enabled: false,
			},
		};
		const { storage } = this.featuresConfiguration;
		return {
			...defaults,
			...storage,
		};
	}

	@computed
	get acceptanceCheckboxesConfiguration(): IAcceptanceCheckboxes[] | undefined {
		return this.featuresConfiguration?.acceptanceCheckboxes;
	}

	@computed
	get optInCheckboxConfiguration(): IOptInCheckbox | undefined {
		return this.featuresConfiguration?.storage?.optInCheckbox;
	}

	@computed
	get redirectsConfiguration(): IRedirects | undefined {
		return this.featuresConfiguration?.redirects;
	}

	@computed
	get autoAttestConfiguration(): IAutoAttest | undefined {
		return this.featuresConfiguration?.autoAttest;
	}

	@computed
	get isAttestationBootstrapped() {
		if (!this.isInitialized || !this.isBootstrapped || !this.configuration || !this.styles) {
			return false;
		}
		return true;
	}

	@computed
	get defaultAttestationVersion(): string {
		return `v${this.manifest.version}`;
	}

	@computed
	get attestationVersion(): string {
		return (this.configuration && this.configuration.version) || this.defaultAttestationVersion;
	}

	@action
	openDialog = () => {
		this.showAttestation = true;
	};

	/**
	 * This function is responsible for closing the dialog box.
	 * @param {string} reason - The reason why the dialog box is being closed.
	 * If the reason is not 'escapeKeyDown' or 'backdropClick', or if the mode is not set to 'change', prevent the dialog from closing.
	 * Otherwise reset the userSelectedValues and trigger the handleCultureChange to the initial values.
	 * And hide the attestation dialog box.
	 */
	@action
	closeDialog = (reason: string) => {
		// If the reason is not 'escapeKeyDown' or 'backdropClick', or if the mode is not set to 'change', prevent the dialog from closing
		if (
			(reason !== 'escapeKeyDown' && reason !== 'backdropClick') ||
			((reason === 'escapeKeyDown' || reason === 'backdropClick') && this.showMode === 'change')
		) {
			// Hide the attestation dialog box
			this.showAttestation = false;
			// Reset the userSelectedValues to the initialUserSelectedValues
			this.userSelectedValues = this.initialUserSelectedValues;
			// Trigger the handleCultureChange to the initialSelectedCulture so that the language selector is reset to the initial translations
			this.handleCultureChange(this.initialSelectedCulture);
		}
	};

	handleAcceptClick = async () => {
		await this.setStorageValues();
		// The initialUserSelectedValues are set up in the customInitializeAfter, and once it has been attested, the initialUserSelectedValues are updated with the userSelectedValues.
		// So the next time the dialog is open the attested values are used.
		this.initialUserSelectedValues = { ...this.userSelectedValues };
		this.redirect('accept');
		this.closeDialog('accepted');
	};

	handleRejectClick = () => {
		this.redirect('reject');
	};

	handleCheckboxClick = (id: string, checked: boolean) => {
		// Need to handle the checkboxes based on their id
		// Opt In Checkbox
		if (id === 'opt-in') {
			// Allow storage based on user interaction
			this.setOptIn(checked);
		}
		// Acceptance Check boxes
		if (this.acceptanceCheckboxesIds.includes(id)) {
			this.acceptanceStore.setAcceptanceState(id, checked);
		}
		// TODO: Handle any other checkbox value selection here.
	};

	handleCultureChange = async (value) => {
		if (value) {
			await this.translationStore.setCulture(value);
			// re-initialize the disclaimer store to get the correct disclaimers based on the culture
			this.disclaimerStore.initialize();
			this.setUserSelectedValuesFromControls('culture', value);
		}
	};

	/**
	 * This will handle setting the user selected values computed
	 * @param key Should line up to the step key the control is sitting in
	 * @param value The value of the control
	 */
	@action
	setUserSelectedValuesFromControls = (key: TAttestationKey, value: string) => {
		const { setAcceptanceState, acceptanceState } = this.acceptanceStore;
		//set all acceptance states to false
		acceptanceState.forEach((value: boolean, key: string) => {
			if (value) {
				setAcceptanceState(key, false);
			}
		});
		this.userSelectedValues = { ...this.userSelectedValues, [key]: value };
		if (['culture', 'country'].includes(key)) {
			if (this.languageSelectorStore) {
				this.languageSelectorStore.initializeConditionalItems(key === 'culture' ? value : null);
			}
			if (key === 'culture') {
				this.userSelectedValues = this.applyCultureAlias(
					this.userSelectedValues,
					this.translationStore.culture,
				);
			}
			// Loop through each key in userSelectedValues
			Object.keys(this.userSelectedValues).forEach((key: TAttestationKey) => {
				// Check if the selection for the key is valid
				if (!this.isSelectionValid(key, this.userSelectedValues[key])) {
					// If the selection is not valid, remove the key from userSelectedValues
					const { [key]: property, ...rest } = this.userSelectedValues;
					this.userSelectedValues = {
						...rest,
					};
				}
			});
		}
	};

	@computed
	get userSelectedCulture() {
		return this.userSelectedValues.culture;
	}

	@action
	async getHasAttested(): Promise<void> {
		let previousAttestations = (await this.getStoredAttestation()) || [];
		// Previous Attestation can be undefined if the attestation expired or if it has not been set
		if (previousAttestations) {
			// Fix incase it is not an array
			if (!Array.isArray(previousAttestations)) {
				previousAttestations = [previousAttestations];
			}
			this.hasAttested = previousAttestations.some((previousAttestation) =>
				this.previousAttestationMatchesInputs(previousAttestation),
			);
			this.previousAttestations = previousAttestations;
		}
		// We will preset values based off of config from other sources other than the storage sources
		this.setUserSelectedValuesFromStorage();
	}

	@action
	setUserSelectedValuesFromStorage() {
		const { initialSelection } = this.featuresConfiguration;
		if (initialSelection) {
			let { orderOfPrecedence } = initialSelection;
			if (orderOfPrecedence && Array.isArray(orderOfPrecedence) && orderOfPrecedence.length === 0) {
				// If the orderOfPrecedence is an empty array or null dont prefill the step values
				return;
			}
			if (!orderOfPrecedence) {
				orderOfPrecedence = ['STORAGE', 'INPUTS'];
			}
			let selectedValues = {} as TUserSelections;
			for (const order of orderOfPrecedence) {
				// Due to the order of precedence firing of in the order requested,
				// once the values from the different sources are retrieved then the current
				// values will be spread after to override the current source's values
				switch (order as TStorageSource) {
					case 'GEOLOCATION': {
						const geolocationValues = this.setSelectionValuesByGeolocation(initialSelection.geolocation);
						selectedValues = {
							...geolocationValues,
							...selectedValues,
						};
						break;
					}
					case 'STORAGE': {
						// Handle if no previous attestation or if user has yet to attest
						// If multiple attestations is stored pre-populate based off of the inputs instead
						if (!this.allowMultipleAttestations && this.previousAttestations.length > 0) {
							const { values } = this.previousAttestations[0];
							selectedValues = {
								...values,
								...selectedValues,
							};
						}
						break;
					}
					case 'INPUTS': {
						const { inputs } = this.appParamsHelper.values;
						// Will need to get the inputs that matches the TAttestationKey
						const inputsKeys = Object.keys(inputs);
						const values: TUserSelections = {};
						for (const key of inputsKeys) {
							if (EAttestationKeys[key]) {
								const inputValue = inputs[key];
								values[key] = inputValue;
							}
						}
						selectedValues = {
							...values,
							...selectedValues,
						};
						break;
					}
				}
			}
			if (selectedValues) {
				const validation = this.validateSelections(selectedValues);
				if (validation.validSelections) {
					this.userSelectedValues = validation.validSelections;
				}
			}
		}
	}

	/**
	 * Used to get allowed step values relative to the provided selections
	 *
	 * @param selectedValues
	 * @returns
	 */
	private getStepValuesForSelectionValidation(selectedValues: Partial<Record<TAttestationKey, string>>) {
		return Array.from(this.stepItemsLookup.keys()).reduce(
			(
				filteredStepValues: Partial<Record<TAttestationKey | 'language', IItem[]>>,
				stepKey: TAttestationKey | 'language',
			) => {
				if (stepKey === 'culture' || stepKey === 'language') {
					const filteredItems = this.languageSelectorStore.dropdownProps?.items.filter((item) => {
						return this.applyInstanceToConditional(selectedValues, item.conditional);
					});
					filteredStepValues[stepKey] = filteredItems;
				} else {
					const filteredItems = Array.from(this.stepItemsLookup.get(stepKey).values()).filter((item) => {
						return this.applyInstanceToConditional(selectedValues, item.conditional);
					});
					filteredStepValues[stepKey] = filteredItems;
				}
				return filteredStepValues;
			},
			{},
		);
	}

	/**
	 * Used to filter the provided selected values based on provided allowed step keys & values (step keys and values should map up)
	 *
	 * @param stepKeys
	 * @param stepValues
	 * @param selectedValues
	 * @returns
	 */
	private filterSelectionsByStepValues(
		stepKeys: string[],
		stepValues: Record<string, IItem[]>,
		selectedValues: Record<string, string>,
	) {
		return stepKeys.reduce(
			(filteredValues: Partial<Record<TAttestationKey, string>>, stepKey: TAttestationKey | 'language') => {
				const allowedValues = stepValues[stepKey];
				let targetStepKey = stepKey;
				if (stepKey === 'language') {
					targetStepKey = 'culture';
				}
				if (allowedValues && allowedValues.some((item) => item.value === selectedValues[targetStepKey])) {
					filteredValues[stepKey] = selectedValues[targetStepKey];
				}
				return filteredValues;
			},
			{},
		);
	}

	/**
	 * Validates the provided selections, ensuring all provided values meeting the conditions of the items they relate to.
	 * Returning whether all selections are valid overall, and a partial list of valid selections made.
	 *
	 * @param selectedValues
	 * @returns {{valid: boolean; validSelections: Partial<Record<TAttestationKey, string>>}}
	 */
	validateSelections(selectedValues: Partial<Record<TAttestationKey, string>>) {
		if (selectedValues) {
			const stepValues = this.getStepValuesForSelectionValidation(selectedValues);
			const stepKeys = Object.keys(stepValues);
			const validSelections = this.filterSelectionsByStepValues(stepKeys, stepValues, selectedValues);
			const filteredKeys = Object.keys(validSelections);
			const valid = stepKeys.every((key) => {
				const values = stepValues[key];
				// if the step doesn't have allowed values, it auto passes.
				if (!values || values.length <= 0) {
					return true;
				}
				// otherwise the step value should exist in the filtered list.
				return filteredKeys.find((fk) => fk === key) !== undefined;
			});
			return {
				valid,
				validSelections,
			};
		}
		return {};
	}

	previousAttestationMatchesInputs(previousAttestation: IAttestation) {
		if (this.appParamsHelper.values && previousAttestation) {
			const { inputs } = this.appParamsHelper.values;
			const { values } = previousAttestation;
			const keys = Object.keys(values);
			return !keys.some((key) => {
				return !common.commonUtils.isNullOrEmpty(inputs[key]) && values[key] !== inputs[key];
			});
		}
	}

	async getStoredAttestation(): Promise<IAttestation[] | undefined> {
		let attestations = (await this.storageHelper.getStorageValues(AppStore.storageKey)) as IAttestation[];
		if (attestations) {
			if (!Array.isArray(attestations)) {
				attestations = [attestations];
			}

			if (attestations.length <= 0) {
				return undefined;
			}

			if (!this.storage?.expiry) {
				this.log('warning', {
					message: 'Storage options not configured with `expiry`, previous attestations will be cleared',
					additionalContext: 'Get Stored Attestation',
					detail: this.storage,
				});
				return undefined;
			}

			let validAttestations = common.commonUtils.sortByDate(
				attestations.filter((attestation) => {
					const { time } = attestation;
					// Check the expiration
					const timeAsDate = new Date(Date.parse(time));
					// Just check if the storage date has not passed the current date since the time would be set when it will expire
					return !common.commonUtils.hasDatePassed(timeAsDate);
				}),
				(value) => value.time,
				'DESC',
			);
			// House keeping
			if (!this.allowMultipleAttestations && validAttestations.length > 1) {
				const latestAttestation = validAttestations[0];
				this.log('warning', {
					message: `AppStore.getStoredAttestation: Multiple attestations found while multiple attestations is not allowed. Using: ${JSON.stringify(
						latestAttestation,
					)}`,
				});
				validAttestations = [latestAttestation];
			}
			if (validAttestations?.length !== attestations.length) {
				// Set the remaining attestations
				this.resetStorageValues(validAttestations);
			}
			if (validAttestations.length > 0) {
				return validAttestations;
			}
		}
		return undefined;
	}

	// House keeping function to reset the storage values if any has expired
	async resetStorageValues(attestations: IAttestation[]): Promise<boolean> {
		// If the remaining attestations is an empty array then rather clear the storage key.
		let result = false;
		if (attestations.length === 0) {
			result = await this.storageHelper.removeStorageValues(AppStore.storageKey);
		} else {
			result = await this.storageHelper.setStorageValues(AppStore.storageKey, attestations);
		}
		if (!result) {
			this.log('warning', {
				message: `AppStore.resetStorageValues: Resetting of attestation values failed for storage type: ${this.storageHelper.type}`,
			});
		}
		return result;
	}

	async setStorageValues(): Promise<void> {
		if (this.userOptIn) {
			const expiry = this.storageHelper.getExpiryDate();
			const attestation: IAttestation = {
				values: this.userSelectedValues,
				time: expiry,
				version: this.attestationVersion,
			};
			const attestations: IAttestation[] = [attestation];
			if (this.allowMultipleAttestations) {
				for (const previousAttestation of this.previousAttestations) {
					attestations.push(previousAttestation);
				}
			}
			const result = await this.storageHelper.setStorageValues(AppStore.storageKey, attestations);
			if (!result) {
				this.log('warning', {
					message: `AppStore.setStorageValues: Saving of attestation value failed for storage type: ${this.storageHelper.type}`,
				});
			}
		}
	}

	setSelectionValuesByGeolocation(geolocationConfig?: IGeolocation[]): TUserSelections {
		// The geolocation is optional. This is to handel setting of the geolocation orderOfPrecedence
		// But forgot to configure the Geolocation
		if (!geolocationConfig) return;

		const selectedValues = {} as TUserSelections;
		for (const geolocation of geolocationConfig) {
			const { stepKey, conditionalMapping } = geolocation;
			let conditionalValue;
			if (conditionalMapping) {
				const mappingKeys = Object.keys(conditionalMapping);
				if (mappingKeys.includes(this.userCountryCode)) {
					conditionalValue = conditionalMapping[this.userCountryCode];
				} else if (mappingKeys.includes('default')) {
					conditionalValue = conditionalMapping['default'];
				}
			}
			if (conditionalValue) {
				selectedValues[stepKey] = conditionalValue;
			}
		}
		return selectedValues;
	}

	debugLog(message?: any, ...optionalParams: any[]) {
		if (this.isDebug) {
			console.debug(message, ...optionalParams);
		}
	}

	@computed
	get disclaimerStore(): DisclaimerStore {
		return this.storeContext.get<DisclaimerStore>('disclaimerStore');
	}

	@computed
	get languageSelectorStore(): DropdownStore {
		if (this.featuresConfiguration.languageSelector) {
			return this.storeContext.get<DropdownStore>('languageSelectorStore');
		}
	}

	@computed
	get linkStore(): LinkStore {
		return this.storeContext.get<LinkStore>('linkStore');
	}

	@computed
	get acceptanceStore(): AcceptanceStore {
		return this.storeContext.get<AcceptanceStore>('acceptanceStore');
	}

	@computed
	get translationStore(): TranslationStore {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@computed
	get disableAttestStore(): DisableAttestStore {
		return this.storeContext.get<DisableAttestStore>('disableAttestStore');
	}

	getInput(inputKey: keyof IInputs) {
		return this.appParamsHelper.inputs && this.appParamsHelper.inputs[inputKey];
	}
}
