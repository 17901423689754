import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Box from '@mui/material/Box/Box.js';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel.js';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup.js';
import Typography from '@mui/material/Typography/Typography.js';

import { useMuiStyles } from '../../../../../hooks/useMuiStyles.js';
import { IRadioButtonProps } from '../../models/IRadioButtonProps.js';
import { MuiRadioButton } from '../MuiRadioButton/MuiRadioButton.js';

import { IRadioButtonListStyles } from './models/IRadioButtonListStyles.js';

const Translate = components.base.Translate.Translate;

export const MuiRadioButtonList: React.FC<IRadioButtonProps> = ({
	selectedValue: currentValue,
	selectionItem,
	handleChange,
}) => {
	const styles = useMuiStyles('muiRadioButtonList') as IRadioButtonListStyles;

	if (!selectionItem.items) {
		return null;
	}

	const { iconAlt, iconSrc, label, value } = selectionItem;

	return (
		<RadioGroup
			sx={styles?.radioGroup?.styles}
			data-selector={`attestation-stepper-radio-button-group`}
			aria-labelledby="demo-controlled-radio-buttons-group"
			name="controlled-radio-buttons-group"
			value={currentValue}
			onChange={handleChange}
		>
			<Box sx={styles?.radioGroup?.box?.styles} data-selector={`attestation-stepper-radio-button-box`}>
				<FormControlLabel
					sx={styles?.radioGroup?.box?.fromControlLabel?.styles}
					data-selector={`attestation-stepper-radio-button-form-control`}
					value={value || label}
					control={
						<MuiRadioButton
							label={label}
							value={value || label}
							src={iconSrc}
							alt={iconAlt}
							sx={styles?.radioGroup?.box?.fromControlLabel?.radioButton?.styles}
							data-selector={`attestation-stepper-radio-button`}
						/>
					}
					label={
						<Typography
							sx={styles?.radioGroup?.box?.fromControlLabel?.label?.styles}
							data-selector={`attestation-stepper-radio-button-label`}
							fontWeight={currentValue === (value || label) ? 800 : 400}
						>
							<Translate>{label}</Translate>
						</Typography>
					}
				/>
			</Box>
		</RadioGroup>
	);
};
