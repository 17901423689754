import React from 'react';
import Radio from '@mui/material/Radio/Radio.js';

import { IRadioButtonProps } from './models/IRadioButtonProps.js';

export const MuiRadioButton: React.FC<IRadioButtonProps> = ({ label, src, alt, ...props }) => {
	const defaultImgSize = { width: '20px', height: '15px' };
	return (
		<Radio
			{...props}
			checkedIcon={
				src && <img {...defaultImgSize} src={src} alt={alt || `${label} checked radio button label icon`} />
			}
			icon={
				src && <img {...defaultImgSize} src={src} alt={alt || `${label} unchecked radio button label icon`} />
			}
		/>
	);
};
