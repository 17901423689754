import React from 'react';

import { ILinkProps } from './models/ILinkProps';
import { ILinksProps } from './models/ILinksProps';
import { Link } from './Link';

export const Links: React.FC<ILinksProps> = ({ links, styles }) => {
	return (
		<>
			{links?.map((link: ILinkProps, index: number) => {
				// TODO: Pass through more context to build up a better key.
				return (
					<Link
						key={`link-${index}`}
						styles={styles}
						href={link.href}
						target={link.target}
						text={link.text}
					/>
				);
			})}
		</>
	);
};
