import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Box from '@mui/material/Box/Box.js';
import Button from '@mui/material/Button/Button.js';
import Card from '@mui/material/Card/Card.js';
import Typography from '@mui/material/Typography/Typography.js';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { AppStore } from '../../../../../App/stores/AppStore.js';
import { useMuiStyles } from '../../../../../hooks/useMuiStyles.js';
import { useStores } from '../../../../../hooks/useStores.js';
import { IButton } from '../../../../models/interfaces/IButton.js';

import { ICardProps } from './models/ICardProps.js';
import { ICardStyles } from './models/ICardStyles.js';

const Translate = components.base.Translate.Translate;

export const MuiCard: React.FC<ICardProps> = ({
	items,
	button,
	pressedButton,
	activeStepKey,
	selectedValue,
	gridContainerOptions,
	gridOptions,
	setUserSelectedValues,
}) => {
	const [currentValue, setValue] = React.useState(selectedValue || null);
	const [filterItems, setFilterItems] = React.useState(items);
	const appStore = useStores<AppStore>('appStore');

	const styles = useMuiStyles('muiCard') as ICardStyles;

	const handleOnClick = (value: string) => {
		setUserSelectedValues(activeStepKey, value);
	};

	React.useEffect(() => {
		let hasSelectedValue = false;
		const filteredItems = items.filter((item) => {
			if (item.value?.toLowerCase() === selectedValue?.value?.toLowerCase()) {
				hasSelectedValue = true;
			}
			const isConditionValid = appStore.isConditionValid(item.conditional);
			return isConditionValid;
		});
		setFilterItems(filteredItems);
		setValue(hasSelectedValue ? selectedValue : null);
	}, [selectedValue, items, appStore]);

	if (!items) {
		return null;
	}

	const defaultButtonState: IButton = {
		variant: 'outlined',
		color: 'primary',
	};

	const buttonState: IButton = { ...defaultButtonState, ...button };
	const pressedButtonState: IButton = { ...buttonState, ...pressedButton };

	return (
		<Grid container {...gridContainerOptions} data-selector={`attestation-stepper-selection-card-grid-container`}>
			{filterItems.map((selectionItem) => {
				// Apply aria pressed and secondary color on the button if card is selected
				const isSelectedCard =
					currentValue && currentValue?.value?.toLowerCase() === selectionItem.value?.toLowerCase();
				const buttonStateToUse = isSelectedCard ? pressedButtonState : buttonState;
				return (
					<Grid
						{...gridOptions}
						data-selector={`attestation-stepper-selection-card-grid-item`}
						key={`attestation-stepper-selection-card-grid-item-${
							selectionItem.value || selectionItem.label
						}`}
					>
						<Card
							data-selector={`attestation-selection-card`}
							elevation={0}
							sx={styles.card?.styles}
							key={`attestation-selection-card`}
						>
							<Box sx={styles.card?.box?.styles} data-selector={`attestation-stepper-card-text-box`}>
								<Typography
									sx={styles.card?.box?.label?.styles}
									variant="h6"
									data-selector={`attestation-stepper-card-label`}
								>
									<Translate>{selectionItem.label}</Translate>
								</Typography>
								<Typography
									sx={styles.card?.box?.description?.styles}
									variant="body2"
									data-selector={`attestation-stepper-card-description`}
								>
									<Translate>{selectionItem.description}</Translate>
								</Typography>
							</Box>
							<Button
								aria-pressed={isSelectedCard}
								sx={styles?.card?.button?.styles}
								data-selector={`attestation-stepper-card-button`}
								onClick={() => handleOnClick(selectionItem.value)}
								{...buttonStateToUse}
							>
								<Translate>{selectionItem.buttonLabel}</Translate>
							</Button>
						</Card>
					</Grid>
				);
			})}
		</Grid>
	);
};
