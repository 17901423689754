import React, { createContext, ReactNode, useContext } from 'react';

import { IComponentStyles } from '../../models/app/IComponentStyles.js';

export interface IMuiStyleContextProvider {
	value: IComponentStyles;
	children: ReactNode;
}

export const MuiStyleContext = createContext({});

export function MuiStyleContextProvider({ value, children }: IMuiStyleContextProvider) {
	return <MuiStyleContext.Provider value={value}>{children}</MuiStyleContext.Provider>;
}

export function useMuiStyles(componentStyleKey: keyof IComponentStyles) {
	const styles = useContext(MuiStyleContext);
	if (styles[componentStyleKey]) {
		return styles[componentStyleKey];
	}

	return {};
}
