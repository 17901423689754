import React from 'react';
import { appsManager } from '@kurtosys/ksys-app-template';
import styled from 'styled-components';

import { ISkeletonLoader, ISkeletonLoaderVariable } from '../../models/commonTypes.js';
const SkeletonLoaderHandler = appsManager.SkeletonLoader;
interface SkeletonLoaderProps {
	config: ISkeletonLoader;
	variables?: ISkeletonLoaderVariable[];
}

export const SkeletonLoader: React.FC<SkeletonLoaderProps> = (props) => {
	const { config, variables = [] } = props;
	const skeletonLoader = new SkeletonLoaderHandler(config, variables);
	const Skeleton = styled.div`
		${skeletonLoader.getSkeletonCss()}
	`;
	return <Skeleton />;
};

export default SkeletonLoader;
