import { TemplateStore } from '@kurtosys/app-loader/dist/TemplateStore.js';
import { appStartUtils, stores } from '@kurtosys/app-start';
import { IManifest } from '@kurtosys/types/appsManager/index.js';

import { AppStore } from '../components/App/stores/AppStore.js';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore.js';
import { setupStoreContext } from '../configuration/setupStoreContext.js';
import { TEMPLATE_STORE } from '../index.js';
import { IComponentStyles } from '../models/app/IComponentStyles.js';
import { IConfiguration } from '../models/app/IConfiguration.js';
import { TStoreContext } from '../models/app/TStoreContext.js';

import { getMockData } from './getMockData.js';

export async function getStoreContextForElement(
	element: HTMLElement,
	url: string,
	manifest: IManifest,
	storeKey?: string,
	reInitializeStore = false,
) {
	storeKey = storeKey || appStartUtils.getStoreKey(element, url, manifest);
	if (storeKey) {
		let storeContext: TStoreContext | undefined;
		if (!(window as any)[storeKey]) {
			storeContext = new stores.StoreContext<
				IConfiguration,
				IComponentStyles,
				AppStore,
				KurtosysApiStore,
				TemplateStore
			>(manifest);
			const mockData = await getMockData();
			const appStore = new AppStore(element, url, storeContext, manifest, mockData);
			const kurtosysApiStore = new KurtosysApiStore(storeContext, manifest);
			storeContext.setup(appStore, kurtosysApiStore, TEMPLATE_STORE, setupStoreContext);
			(window as any)[storeKey] = storeContext;
		} else {
			storeContext = (window as any)[storeKey] as TStoreContext;
			if (reInitializeStore && storeContext && storeContext.reInitialize) {
				storeContext.reInitialize();
			}
		}
		return storeContext;
	}
}
