import React, { useEffect } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import FormControl from '@mui/material/FormControl/FormControl.js';
import InputLabel from '@mui/material/InputLabel/InputLabel.js';
import MenuItem from '@mui/material/MenuItem/MenuItem.js';
import Select from '@mui/material/Select/Select.js';

import { useMuiStyles } from '../hooks/useMuiStyles';

import { IMuiDropdownProps } from './models/IMuiDropdownProps';
import { IMuiDropdownStyles } from './models/MuiDropdownStyles';

const Translate = components.base.Translate.Translate;

export const MuiDropdown: React.FC<IMuiDropdownProps> = ({
	label,
	placeholder,
	items,
	selectedItem,
	singleItemOptions,
	handleOnChange,
	disabled,
}) => {
	if (items.length === 0) {
		return null;
	}
	if (items.length === 1) {
		if (singleItemOptions?.hide === true) {
			return null;
		} else if (singleItemOptions?.readonly === true) {
			disabled = true;
		}
	}

	const styles = useMuiStyles('muiDropdown') as IMuiDropdownStyles;

	const [selectedValue, setSelectedValue] = React.useState(selectedItem ?? '');
	const [selectItems, setSelectItems] = React.useState(items);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		setSelectedValue(value);
		if (handleOnChange) {
			handleOnChange(event);
		}
	};

	// reset the list and selected item when the conditionals are refired
	useEffect(() => {
		setSelectItems(items);
		setSelectedValue(selectedItem);
	}, [items, selectedItem]);

	return (
		<FormControl
			data-selector={`attestation-stepper-dropdown-container`}
			key={'attestation-stepper-dropdown-container'}
			sx={styles.formControl?.styles}
			disabled={disabled}
		>
			<InputLabel
				sx={styles.formControl?.label?.styles}
				data-selector={`attestation-stepper-label`}
				key={`attestation-stepper-label`}
			>
				<Translate>{label}</Translate>
			</InputLabel>
			<Select
				sx={styles.formControl?.select?.styles}
				data-selector={`attestation-stepper-dropdown`}
				key={`attestation-stepper-dropdown`}
				value={selectedValue}
				label={<Translate>{label}</Translate>}
				onChange={handleChange}
			>
				{placeholder && (
					<MenuItem sx={styles.formControl?.select?.menuItem?.styles} disabled value="">
						<em>
							<Translate>{placeholder}</Translate>
						</em>
					</MenuItem>
				)}
				{selectItems?.map((item) => {
					return (
						<MenuItem
							sx={styles.formControl?.select?.menuItem?.styles}
							key={`attestation-dropdown-item-${item.value}`}
							value={item.value}
						>
							<Translate>{item.label}</Translate>
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
