import { ILayoutsConfig } from '../../App/models/ILayoutsConfig';

/**
 * This is the white label layout that is used as a default layout
 * if no MuiStepper.defaultLayout or MuiStepper.steps[].layout is supplied in the config
 * @title White Label Layout
 */
export const WHITE_LABEL_LAYOUT: ILayoutsConfig = {
	header: {
		options: { xs: 12, spacing: 2 },
		grid: [
			{ components: [{ key: 'stepper' }], options: { xs: 12, sx: { marginTop: 3 } } },
			{ components: [{ key: 'title', config: { options: { variant: 'h4' } } }], options: { xs: 12 } },
			{ components: [{ key: 'description' }], options: { xs: 12, md: 6 } },
		],
	},
	body: {
		options: { spacing: 2 },
		grid: [
			{ components: [{ key: 'selection' }], options: { xs: 12 } },
			{ components: [{ key: 'disclaimers' }], options: { xs: 12 } },
			{ components: [{ key: 'checkboxes' }], options: { xs: 12, sx: { textAlign: 'right' } } },
		],
	},
	footer: {
		options: { xs: 12, spacing: 2 },
		grid: [
			{ components: [{ key: 'actions' }], options: { xs: 12 } },
			{ components: [{ key: 'links' }], options: { xs: 12, sx: { textAlign: 'justify' } } },
		],
	},
};
