import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Box from '@mui/material/Box/Box.js';
import Button from '@mui/material/Button/Button.js';

import { useMuiStyles } from '../../../hooks/useMuiStyles.js';

import { IMuiActionsProps } from './models/IMuiActionsProps.js';
import { IMuiActionStyles } from './models/IMuiActionStyles.js';

const Translate = components.base.Translate.Translate;

export const MuiActions: React.FC<IMuiActionsProps> = ({
	config,
	activeStep,
	stepsLength,
	isAcceptDisabled,
	currentStepValue,
	setActiveStep,
	handleAcceptClick,
	handleRejectClick,
}) => {
	const styles = useMuiStyles('muiActions') as IMuiActionStyles;

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleAccept = () => {
		handleAcceptClick();
	};

	const handleReject = () => {
		handleRejectClick();
	};

	return (
		<Box
			sx={{ justifyContent: config.align, ...styles?.box?.styles }}
			data-selector="attestation-stepper-actions-box"
		>
			{config.displayOrder.map((button) => (
				<React.Fragment key={`attestation-stepper-actions-${button}`}>
					{config.showNavigationActions && button === 'back' && (
						<Button
							sx={styles?.box?.backButton?.styles}
							disabled={activeStep === 0}
							onClick={handleBack}
							data-selector="attestation-stepper-actions-back-button"
							key={`attestation-stepper-actions-back-button`}
						>
							<Translate>{String(config.back)}</Translate>
						</Button>
					)}
					{config.showNavigationActions && button === 'next' && (
						<Button
							sx={styles?.box?.nextButton?.styles}
							onClick={handleNext}
							disabled={activeStep === stepsLength || !currentStepValue}
							data-selector="attestation-stepper-actions-next-button"
							key={`attestation-stepper-actions-next-button`}
						>
							<Translate>{String(config.next)}</Translate>
						</Button>
					)}
					{button === 'reject' && (
						<Button
							sx={styles?.box?.rejectButton?.styles}
							onClick={handleReject}
							data-selector="attestation-stepper-actions-reject-button"
							key={`attestation-stepper-actions-reject-button`}
						>
							<Translate>{String(config.reject)}</Translate>
						</Button>
					)}
					{button === 'accept' && (
						<Button
							sx={styles?.box?.acceptButton?.styles}
							disabled={isAcceptDisabled}
							onClick={handleAccept}
							data-selector="attestation-stepper-actions-accept-button"
							key={`attestation-stepper-actions-accept-button`}
						>
							<Translate>{String(config.accept)}</Translate>
						</Button>
					)}
				</React.Fragment>
			))}
		</Box>
	);
};
