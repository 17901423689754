import { Components, createTheme, Theme, ThemeOptions } from '@mui/material/styles';

import { IComponentStyles, IStyles } from '../../models';

export function CreateMuiTheme(styles: IStyles<IComponentStyles>): Theme {
	if (styles.theme?.mui) {
		const { components = {}, ...muiThemeStyles } = styles.theme.mui;

		// Transform the component object into the object that Mui's theme expects
		const muiComponents: Components = {};
		for (const component in components) {
			muiComponents[component] = { styleOverrides: { root: components[component] } };
		}

		const themeOptions: ThemeOptions = {
			...muiThemeStyles,
			components: { ...muiComponents },
		};

		return createTheme(themeOptions);
	}
}
