export enum EAttestationKeys {
	country = 'country',
	investor = 'investor',
	acceptance = 'acceptance',
	culture = 'culture',
	// The language selector alias
	cultureAlias = 'cultureAlias',
}

export type TAttestationKey = keyof typeof EAttestationKeys;
