import {stores } from '@kurtosys/app-start';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { action, computed, makeObservable, observable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';

import { AppStore } from './AppStore';

const StoreBase = stores.base.StoreBase<IConfiguration, IComponentStyles>;

export class AcceptanceStore extends StoreBase {
	private acceptanceKeys: string[] = [];
	@observable acceptanceState: Map<string, boolean> = new Map();

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore() {
		return this.storeContext.get<AppStore>('appStore');
	}

	async initialize(): Promise<void> {
		for (const config of this.acceptanceCheckboxesConfig) {
			this.acceptanceKeys.push(config.key);
			this.setAcceptanceState(config.key, false);
		}
	}

	@computed
	get featuresConfiguration() {
		return this.appStore.appComponentConfiguration?.features;
	}

	@computed
	get acceptanceCheckboxesConfig() {
		return this.featuresConfiguration?.acceptanceCheckboxes;
	}

	@action
	setAcceptanceState = (key: string, value: boolean) => {
		this.acceptanceState.set(key, value);
	};

	getAcceptanceState = (key: string): boolean => {
		return this.acceptanceState.get(key);
	};

	@computed
	get allAcceptanceChecked(): boolean {
		return Array.from(this.acceptanceState.values()).every((value) => value);
	}
}
