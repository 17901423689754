import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Typography from '@mui/material/Typography/Typography.js';

import { useMuiStyles } from '../../../hooks/useMuiStyles.js';

import { ITextDisplayProps } from './models/ITextDisplayProps.js';
import { ITextDisplayStyles } from './models/ITextDisplayStyles.js';

const Translate = components.base.Translate.Translate;

export const MuiTextDisplay: React.FC<ITextDisplayProps> = ({ text, options }) => {
	const styles = useMuiStyles('muiTextDisplay') as ITextDisplayStyles;

	if (!text) {
		return null;
	}

	return (
		<Typography sx={styles?.typography?.styles} data-selector={`attestation-stepper-text-display`} {...options}>
			<Translate>{text}</Translate>
		</Typography>
	);
};
