import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import { FormControlLabel, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox/Checkbox.js';

import { useMuiStyles } from '../../../hooks/useMuiStyles.js';

import { IMuiCheckboxProps } from './models/MuiCheckboxProps.js';
import { IMuiCheckboxStyles } from './models/MuiCheckboxStyles.js';

const Translate = components.base.Translate.Translate;

export const MuiCheckbox: React.FC<IMuiCheckboxProps> = ({ config, onChange }) => {
	const { id, label, disabled = false, isDefaultSelected = false, required = false } = config;
	const styles = useMuiStyles('muiCheckbox') as IMuiCheckboxStyles;
	const [checked, setChecked] = React.useState(isDefaultSelected);

	if (!id && !label) {
		return null;
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const checked = (event.target as HTMLInputElement).checked;
		setChecked(checked);
		if (onChange) {
			onChange(id, checked);
		}
	};

	return (
		<FormControlLabel
			data-selector={'attestation-wizard-checkbox'}
			sx={styles?.formControlLabel?.styles}
			label={
				<Typography sx={styles?.formControlLabel?.label?.styles}>
					<Translate>{label}</Translate>
				</Typography>
			}
			disabled={disabled}
			control={
				<Checkbox
					sx={styles?.formControlLabel?.checkbox?.styles}
					id={id}
					required={required}
					checked={checked}
					onChange={(event) => handleChange(event)}
				/>
			}
		/>
	);
};
