import {stores } from '@kurtosys/app-start';
import { common } from '@kurtosys/ksys-app-template';
import { Query } from '@kurtosys/ksys-app-template/dist/query/Query.js';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { IConditional } from '@kurtosys/types/common/index.js';
import { computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { IDisableDocumentSelectors, IDisableParameters } from '../models/IDisable.js';

import { AppStore } from './AppStore.js';

const StoreBase = stores.base.StoreBase<IConfiguration, IComponentStyles>;

export class DisableAttestStore extends StoreBase {
	private seoRobotConditional: IConditional = {
		conditions: [
			{
				fieldQuery: {
					queryOptionsType: 'dynamic',
					options: {
						dynamic: {
							key: 'user-agent',
						},
					},
				},
				operator: 'regex-test',
				value: 'APIs-Google|Mediapartners-Google|AdsBot-Google-Mobile|AdsBot-Google|Googlebot|AdsBot-Google-Mobile-Apps|FeedFetcher-Google|Google-Read-Aloud|DuplexWeb-Google',
			},
		],
	};
	private defaultParameter: IDisableParameters = { name: 'isSnapshot' };
	private defaultSelector: IDisableDocumentSelectors = { selector: 'body.elementor-editor-active' };

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	async initialize(callbacks: Record<string, EventListener>): Promise<void> {
		console.warn('DisableAttestStore does not implement initialze method, yet..');
		return;
	}

	@computed
	get appStore() {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get disableAttestConfiguration() {
		return this.appStore.featuresConfiguration?.disable || {};
	}

	@computed
	get attestationIsDisabled(): boolean {
		const { parameters, documentSelectors, conditional } = this.disableAttestConfiguration;
		return (
			this.getDisabledStatusFromParameters(parameters) ||
			this.getDisabledStatusFromSelectors(documentSelectors) ||
			this.getDisabledStatusFromConditionals(conditional)
		);
	}

	getDisabledStatusFromParameters(rawParameters?: IDisableParameters[]): boolean {
		const parameters = [this.defaultParameter];
		if (rawParameters) {
			for (const rawParameter of rawParameters) {
				parameters.push(rawParameter);
			}
		}
		const { searchParams } = this.appStore.url;
		let disabled = false;
		for (const parameter of parameters) {
			if (searchParams.has(parameter.name) && searchParams.get(parameter.name) === 'true') {
				disabled = true;
				break;
			}
		}
		return disabled;
	}

	getDisabledStatusFromSelectors(rawSelectors?: IDisableDocumentSelectors[]): boolean {
		const selectors = [this.defaultSelector];
		if (rawSelectors) {
			for (const rawSelector of rawSelectors) {
				selectors.push(rawSelector);
			}
		}
		let disabled = false;
		for (const selector of selectors) {
			const found = document.querySelector(selector.selector);
			if (found) {
				disabled = true;
				break;
			}
		}
		return disabled;
	}

	getDisabledStatusFromConditionals(configConditional?: IConditional): boolean {
		// Cant push the config conditionals into the default SEO conditions as the conditionals is being treated as an AND clauses
		// Thus we will need to check if not a SEO robot Or the config conditionals.
		const seoConditionalHelper = new common.helpers.ConditionalHelper(
			{ queryClass: Query },
			this.seoRobotConditional,
		);
		const isSEORobot = seoConditionalHelper.matchesWithOptions({
			executionOptions: this.appStore.queryStore.executionOptions,
		});
		let configResult = false;
		if (configConditional) {
			const configConditionalHelper = new common.helpers.ConditionalHelper(
				{ queryClass: Query },
				configConditional,
			);
			configResult = configConditionalHelper.matchesWithOptions({
				executionOptions: this.appStore.queryStore.executionOptions,
			});
		}
		return isSEORobot || configResult;
	}
}
