import React from 'react';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { AppStore } from '../../../../../App/stores/AppStore.js';
import { useStores } from '../../../../../hooks/useStores.js';
import { MuiRadioButtonGroup } from '../MuiRadioButtonGroup/MuiRadioButtonGroup.js';
import { MuiRadioButtonList } from '../MuiRadioButtonList/MuiRadioButtonList.js';

import { IRadioButtonsProps } from './models/IRadioButtonsProps.js';

export const MuiRadioButtons: React.FC<IRadioButtonsProps> = ({
	items,
	activeStepKey,
	selectedValue: initialSelectedValue,
	gridContainerOptions,
	gridOptions,
	setUserSelectedValues,
}) => {
	const [selectedValue, setSelectedValue] = React.useState(initialSelectedValue);

	const appStore = useStores<AppStore>('appStore');

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		const item = appStore.stepItemsLookup.get(activeStepKey)?.get(value);

		if (item) {
			setSelectedValue(item);
			setUserSelectedValues(activeStepKey, item.value || item.label);
		}
	};

	if (!items) {
		return null;
	}

	return (
		<Grid
			container
			width="100%"
			{...gridContainerOptions}
			data-selector={`attestation-stepper-radio-button-outer-grid-container`}
		>
			{items.map((selectionItem) => {
				return (
					appStore.isConditionValid(selectionItem.conditional) && (
						<Grid
							xs={12}
							{...gridOptions}
							data-selector={`attestation-stepper-radio-button-group-grid-item`}
							key={`attestation-selection-grid-item-${selectionItem.label || selectionItem.value}`}
						>
							{selectionItem.isGroup ? (
								<MuiRadioButtonGroup
									selectionItem={selectionItem}
									selectedValue={selectedValue}
									handleChange={handleChange}
								/>
							) : (
								<MuiRadioButtonList
									selectionItem={selectionItem}
									selectedValue={selectedValue}
									handleChange={handleChange}
								/>
							)}
						</Grid>
					)
				);
			})}
		</Grid>
	);
};
