import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Typography from '@mui/material/Typography/Typography.js';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { ILayout } from '../App/models/ILayout.js';
import { CloseButton } from '../CloseButton/CloseButton.js';
import { Disclaimers } from '../Disclaimers/Disclaimers.js';
import { useMuiStyles } from '../hooks/useMuiStyles.js';
import { Link } from '../Links/Link.js';

import { IMuiHeaderLogoProps, IMuiHeaderTextProps } from './models/IMuiHeaderProps.js';
import { IMuiHeaderStyles } from './models/IMuiHeaderStyles.js';
import { TGridHeaderComponentsProps } from './models/TGridHeaderComponentsProps.js';

const Translate = components.base.Translate.Translate;

export const MuiHeaderText: React.FC<IMuiHeaderTextProps> = ({ text, options, styleKey }) => {
	const styles = useMuiStyles('muiHeader') as IMuiHeaderStyles;
	if (!text) return null;
	return (
		<Typography sx={styles?.[styleKey]?.typography?.styles} data-selector={`attestation-header-text`} {...options}>
			<Translate>{text}</Translate>
		</Typography>
	);
};

export const MuiHeaderLogo: React.FC<IMuiHeaderLogoProps> = ({ src, alt }) => {
	const styles = useMuiStyles('muiHeader') as IMuiHeaderStyles;
	if (!src) return null;

	// using as any here to remove the sx prop related styles. Mui will still style this element as normal
	return (
		<img style={styles?.logo?.image?.styles as any} data-selector={`attestation-header-logo`} src={src} alt={alt} />
	);
};

export const MuiHeader: React.FC<ILayout<TGridHeaderComponentsProps>> = ({ grid, options: layoutOptions }) => {
	const styles = useMuiStyles('muiHeader') as IMuiHeaderStyles;
	return (
		<Grid
			data-selector={`attestation-header-grid-container`}
			key={'attestation-header-grid-container'}
			container
			{...layoutOptions}
		>
			{grid?.map(({ components, options }, index) => {
				if (components?.length > 0) {
					return (
						<Grid
							data-selector={`attestation-header-grid-item`}
							key={`attestation-header-grid-item-${index}`}
							{...options}
						>
							{components.map(({ key, config }) => {
								let component = null;
								switch (key) {
									case 'title':
									case 'subTitle':
										component = (
											<MuiHeaderText
												key={`header-text-${key}`}
												text={config.text}
												options={config.options}
												styleKey={key}
											/>
										);
										break;
									case 'logo':
										component = (
											<MuiHeaderLogo
												key={`header-logo-${key}`}
												src={config.src}
												alt={config.alt}
											/>
										);
										break;
									case 'disclaimers':
										component = (
											<Disclaimers
												key={`header-disclaimers-${config?.map((c) => c.key)}`}
												disclaimers={config}
												styles={styles?.disclaimers?.typography?.styles}
											/>
										);
										break;
									case 'previousAttestedLink':
										component = (
											<Link
												key={`header-link-${key}`}
												{...config}
												styles={styles?.previousAttestedLink?.link?.styles}
											/>
										);
										break;
									case 'closeButton':
										component = (
											<CloseButton
												key={`header-close-${key}`}
												closeButton={config}
												styles={styles?.closeButton}
											/>
										);
										break;
									default:
										break;
								}
								return component;
							})}
						</Grid>
					);
				}
			})}
		</Grid>
	);
};
