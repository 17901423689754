/* [Component: storesImport] */
import { stores } from '@kurtosys/app-start';

import { AcceptanceStore } from '../components/App/stores/AcceptanceStore.js';
import { AppStore } from '../components/App/stores/AppStore.js';
import { DisableAttestStore } from '../components/App/stores/DisableAttestStore.js';
import { DisclaimerStore } from '../components/Disclaimers/stores/DisclaimerStore.js';
import { LinkStore } from '../components/Links/stores/LinkStore.js';
import { DropdownStore } from '../components/MuiDropdown/stores/DropdownStore.js';
import { TStoreContext } from '../models/app/TStoreContext';
import { IComponentStyles, IConfiguration } from '../models/index.js';

type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;
type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;

// TODO: Consider moving the defaults to a common space once useStores hook is used more widely
export interface IDefaultStoresIndex {
	appStore: AppStore;
	queryStore?: QueryStore;
	translationStore?: TranslationStore;
}

/**
 * Used to give typings to the useStore hook.
 */
export interface IStoresIndex extends IDefaultStoresIndex {
	disclaimerStore?: DisclaimerStore;
	linkStore?: LinkStore;
	// Add global spaced app specific stores here
}

export function setupStoreContext(storeContext: TStoreContext) {
	storeContext.set('disclaimerStore', new DisclaimerStore(storeContext, storeContext.manifest));
	storeContext.set('linkStore', new LinkStore(storeContext, storeContext.manifest));
	storeContext.set('acceptanceStore', new AcceptanceStore(storeContext, storeContext.manifest));
	storeContext.set('disableAttestStore', new DisableAttestStore(storeContext, storeContext.manifest));
	storeContext.set('languageSelectorStore', new DropdownStore(storeContext, storeContext.manifest));
	/* [Component: stores] */
}
