import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Typography from '@mui/material/Typography/Typography.js';

import { useMuiStyles } from '../hooks/useMuiStyles.js';

import { IDisclaimerProps } from './models/IDisclaimerProps.js';
import { IDisclaimerStyles } from './models/IDisclaimerStyles.js';

const Translate = components.base.Translate.Translate;

export const Disclaimer: React.FC<IDisclaimerProps> = ({ disclaimer, styles }) => {
	const disclaimerStyles = useMuiStyles('disclaimers') as IDisclaimerStyles;
	return (
		<Translate>
			<Typography
				data-selector="attestation-disclaimer-text"
				sx={{ ...(disclaimerStyles?.typography?.styles || {}), ...(styles || {}) }}
				dangerouslySetInnerHTML={{ __html: disclaimer.text }}
			></Typography>
		</Translate>
	);
};
