import { stores } from '@kurtosys/app-start';
import { common, query } from '@kurtosys/ksys-app-template';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { IQueryOptions } from '@kurtosys/types/query/IQueryOptions.js';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { IMuiDropdownConfig } from '../models/IMuiDropdownConfig.js';
import { IMuiDropdownItemsProps } from '../models/IMuiDropdownItemsProps.js';
import { IMuiDropdownProps } from '../models/IMuiDropdownProps.js';
type TranslationStore = stores.TranslationStore<IConfiguration, IComponentStyles>;
const StoreBase = stores.base.StoreBase<IConfiguration, IComponentStyles>;

export interface IMuiSelectedItemProps {
	value: string;
	isUserSelected: boolean;
}

export class DropdownStore extends StoreBase {
	@observable.ref private dropdownConfig: IMuiDropdownConfig = {};
	@observable.ref dropdownProps: IMuiDropdownProps | undefined;
	@observable.ref selectedItem: IMuiSelectedItemProps = {
		value: '',
		isUserSelected: false,
	};
	@observable.ref conditionalItems: IMuiDropdownItemsProps[] = [];
	@observable.ref onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore() {
		return this.storeContext.get<AppStore>('appStore');
	}

	@computed
	get translationStore() {
		return this.storeContext.get<TranslationStore>('translationStore');
	}

	@action
	initializeConditionalItems = (value?: string) => {
		const conditionalItems = [];
		const selectedItem = {
			value: value ?? this.selectedItem.value,
			isUserSelected: !common.commonUtils.isNullOrUndefined(value) ? true : this.selectedItem.isUserSelected,
		};
		let defaultValue = '';
		if (this.dropdownProps?.items) {
			for (const item of this.dropdownProps.items) {
				if (this.appStore.isConditionValid(item.conditional)) {
					const isDefault =
						item.defaultConditional && this.appStore.isConditionValid(item.defaultConditional);
					if (isDefault) {
						defaultValue = item.value;
					}
					conditionalItems.push(item);
				}
			}

			// if selected item is no longer in the list and it was previously user selected, change to the default value
			if (
				!conditionalItems.some((item) => item.value === selectedItem.value) &&
				selectedItem.isUserSelected === true
			) {
				this.selectedItem = {
					value: defaultValue,
					isUserSelected: false,
				};
			}

			// if the selected item was not previously user selected, change to the default value
			if (selectedItem.isUserSelected === false) {
				this.selectedItem = {
					value: defaultValue,
					isUserSelected: false,
				};
			}

			// if one one item in the the list, set it as the selected item regardless of previous user selection
			if (conditionalItems.length === 1) {
				const singleItemValue = conditionalItems[0].value;
				this.selectedItem = {
					value: singleItemValue,
					isUserSelected: singleItemValue !== selectedItem.value ? false : selectedItem.isUserSelected,
				};
			}
		}
		// TODO: The stores (appStore & translationStore) should rather be passed into the dropdownStore
		if (this.translationStore.culture !== this.selectedItem.value) {
			this.appStore.handleCultureChange(this.selectedItem.value);
		}
		this.conditionalItems = conditionalItems;
	};

	@action
	initializeDropdown = (
		dropdownConfig: IMuiDropdownConfig,
		onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
	) => {
		this.onChange = onChange;
		this.dropdownConfig = dropdownConfig;
		this.initialize();
	};

	async initialize(): Promise<void> {
		const items: IMuiDropdownItemsProps[] = [];
		if (this.dropdownConfig?.items) {
			for (const item of this.dropdownConfig.items) {
				items.push({
					defaultConditional: item.defaultConditional,
					conditional: item.conditional,
					label: this.getQueryValueNoTranslation(item.labelQuery),
					value: this.getQueryValueNoTranslation(item.valueQuery),
					alias: this.getQueryValueNoTranslation(item.aliasQuery),
				});
			}
		}

		runInAction(() => {
			this.dropdownProps = {
				label: this.getQueryValueNoTranslation(this.dropdownConfig.labelQuery),
				placeholder: this.getQueryValueNoTranslation(this.dropdownConfig.placeholderQuery),
				singleItemOptions: this.dropdownConfig.singleItemOptions,
				items,
			};
		});
		this.initializeConditionalItems();
	}

	/**
	 * Executes a query and returns the result without translation.
	 * @param {IQueryOptions} queryOptions - The options for the query.
	 * @returns {any} - The result of the query without translation.
	 */
	getQueryValueNoTranslation(queryOptions: IQueryOptions) {
		const queryStore = this.getQueryStore({});
		return new query.Query(queryOptions).execute({ ...queryStore.executionOptions, translate: null });
	}

	@action
	handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = (event.target as HTMLInputElement).value;
		this.selectedItem = {
			value,
			isUserSelected: true,
		};
		if (this.onChange) {
			this.onChange(event);
		}
	};

	@computed
	get props() {
		return {
			...this.dropdownProps,
			items: this.conditionalItems,
			selectedItem: this.selectedItem.value,
			handleOnChange: this.handleOnChange,
		};
	}
}
