import React, { CSSProperties } from 'react';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';

import { AppStore } from '../App/stores/AppStore.js';
import { useStores } from '../hooks/useStores.js';

import { ICloseButtonProps } from './models/ICloseButtonProps.js';

export const CloseButton: React.FC<ICloseButtonProps> = ({ closeButton = {}, styles: buttonStyles }) => {
	const { text, ariaLabel = 'Close', icon } = closeButton;
	const appStore = useStores<AppStore>('appStore');
	const position = icon && icon.position ? icon.position : 'right';

	const iconComponent = icon ? (
		<img style={buttonStyles?.icon?.styles as CSSProperties} src={icon.src} alt={icon.alt} />
	) : null;

	const buttonContent = (
		<>
			{position === 'left' && iconComponent}
			{text}
			{position === 'right' && iconComponent}
		</>
	);

	const buttonProps = {
		sx: buttonStyles?.styles,
		onClick: () => appStore.closeDialog('onClose'),
	};
	if (!text) {
		buttonProps['aria-label'] = appStore.translate(ariaLabel);
	}

	// Default icon if no icon or text is provided
	if (!icon && !text) {
		return (
			<IconButton sx={buttonStyles?.icon?.styles} {...buttonProps}>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
					<path
						d="M4 4L20 20M20 4L4 20"
						stroke="#333333"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</IconButton>
		);
	}

	return <Button {...buttonProps}>{buttonContent}</Button>;
};
