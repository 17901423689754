import React, { Component } from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Dialog from '@mui/material/Dialog/index.js';
import ThemeProvider from '@mui/material/styles/ThemeProvider.js';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import { MuiStyleContextProvider } from '../hooks/useMuiStyles.js';
import { StoresContextProvider } from '../hooks/useStores.js';
import { MuiHeader } from '../MuiHeader/MuiHeader.js';
import MuiStepper from '../MuiStepper/index.js';
import { CreateMuiTheme } from '../shared/CreateMuiTheme.js';
import DebugInfoAction from '../shared/DebugInfoAction.js';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent.js';
import Loading from '../shared/Loading.js';
import SkeletonLoader from '../shared/SkeletonLoader.js';

import { IAppProps } from './models/IAppProps.js';
import { IAppState } from './models/IAppState.js';

const Application = components.base.Application.Application;

class RawApp extends Component<IAppProps, IAppState> {
	static configurationKey = 'app' as const;
	static styleKey = 'app' as const;

	@computed
	get renderAttestation() {
		const { appStore } = this.props;
		if (appStore) {
			const {
				featuresConfiguration,
				showAttestation,
				closeDialog,
				muiDialogProps,
				muiHeaderLayoutProps,
				muiStepperLayoutProps,
			} = appStore;
			const MuiHeaderComponent = <MuiHeader key={'attestation-header'} {...muiHeaderLayoutProps} />;
			const MuiStepperComponent = <MuiStepper key={'attestation-stepper'} {...muiStepperLayoutProps} />;
			return featuresConfiguration?.displayType === 'modal' ? (
				<Dialog
					// TODO: pass in the sx styling here
					{...muiDialogProps}
					open={showAttestation}
					onClose={(event, reason) => closeDialog(reason)}
				>
					{MuiHeaderComponent}
					{MuiStepperComponent}
				</Dialog>
			) : (
				[MuiHeaderComponent, MuiStepperComponent]
			);
		}
	}

	render() {
		const { className, appStore, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const {
			show,
			isAttestationBootstrapped,
			skeletonLoader,
			skeletonLoaders,
			libraryComponentsConfiguration,
			getTranslateFunction,
			culture,
			assets,
			assetCacheOptions,
			assetRegisters,
			getAccessibilityTextFunction,
			styles,
			showAttestation,
			showMode,
		} = appStore;

		if (!show) {
			return null;
		}

		if (showMode === 'always' && !isAttestationBootstrapped) {
			const { embedLoadingElement } = this.props;

			if (skeletonLoader && !embedLoadingElement) {
				return (
					<SkeletonLoader config={skeletonLoader} variables={skeletonLoaders && skeletonLoaders.variables} />
				);
			}
			return <Loading embedLoadingElement={embedLoadingElement} />;
		}

		const translate = getTranslateFunction();
		const accessibilityText = getAccessibilityTextFunction();
		return (
			<Application
				configuration={libraryComponentsConfiguration}
				translate={translate}
				culture={culture}
				assets={assets}
				assetCacheOptions={assetCacheOptions}
				assetRegisters={assetRegisters}
				accessibilityText={accessibilityText}
				analyticsHelper={appStore.analyticsHelper}
			>
				<MuiStyleContextProvider value={styles.components}>
					<ThemeProvider theme={CreateMuiTheme(styles as any)}>
						<StoresContextProvider stores={{ appStore }}>
							<DebugInfoAction appStore={appStore} />
							{showAttestation && this.renderAttestation}
						</StoresContextProvider>
					</ThemeProvider>
				</MuiStyleContextProvider>
			</Application>
		);
	}
}

export const App = inject('appStore', 'queryStore')(observer(RawApp));
export default InjectedStyledComponent(App, 'app');
