import React from 'react';

import { MuiCard } from './components/MuiCard/MuiCard.js';
import { MuiRadioButtons } from './components/MuiRadioButtons/MuiRadioButtons.js';
import { IMuiSelectionProps } from './models/IMuiSelectionProps.js';

export const MuiSelection: React.FC<IMuiSelectionProps> = ({
	typeOptions,
	items,
	gridContainerOptions,
	gridOptions,
	type = 'radioButtons',
	activeStepKey,
	selectedValue,
	setUserSelectedValues,
}) => {
	if (!items) {
		return null;
	}

	if (type === 'radioButtons') {
		return (
			<MuiRadioButtons
				items={items}
				activeStepKey={activeStepKey}
				selectedValue={selectedValue}
				setUserSelectedValues={setUserSelectedValues}
			/>
		);
	}

	if (type === 'card') {
		return (
			<MuiCard
				gridContainerOptions={gridContainerOptions}
				gridOptions={gridOptions}
				items={items}
				button={typeOptions.card.button}
				pressedButton={typeOptions.card.pressedButton}
				activeStepKey={activeStepKey}
				selectedValue={selectedValue}
				setUserSelectedValues={setUserSelectedValues}
			/>
		);
	}

	return null;
};
