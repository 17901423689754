import React, { createContext, ReactNode, useContext } from 'react';

import { IStoresIndex } from '../../configuration/setupStoreContext';

export interface IStoresContextProvider {
	stores: IStoresIndex;
	children: ReactNode;
}

export const StoresContext = createContext({});

export function StoresContextProvider({ stores, children }: IStoresContextProvider) {
	return <StoresContext.Provider value={stores}>{children}</StoresContext.Provider>;
}

export function useStores<TStore>(storeName: keyof IStoresIndex): TStore {
	const store = useContext(StoresContext);
	return store[storeName];
}
