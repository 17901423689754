import React from 'react';

import { AppStore } from '../App/stores/AppStore.js';
import { useStores } from '../hooks/useStores.js';

import { IDisclaimerRefProps } from './models/IDisclaimerRefProps.js';
import { IDisclaimersProps } from './models/IDisclaimersProps.js';
import { Disclaimer } from './Disclaimer.js';

export const Disclaimers: React.FC<IDisclaimersProps> = ({ disclaimers, styles }) => {
	const appStore = useStores<AppStore>('appStore');

	return (
		<>
			{disclaimers?.map(({ disclaimerValue, conditional }: IDisclaimerRefProps) => {
				if (appStore.isConditionValid(conditional)) {
					return (
						<Disclaimer
							key={`disclaimer-${disclaimerValue.disclaimerType}`}
							disclaimer={disclaimerValue}
							styles={styles}
						/>
					);
				}
				return null;
			})}
		</>
	);
};
