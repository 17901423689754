import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import Box from '@mui/material/Box/Box.js';
import Step from '@mui/material/Step/Step.js';
import StepLabel from '@mui/material/StepLabel/StepLabel.js';

import { AppStore, TUserSelections } from '../../../App/stores/AppStore.js';
import { useMuiStyles } from '../../../hooks/useMuiStyles.js';
import { useStores } from '../../../hooks/useStores.js';
import { IStepProps } from '../../models/interfaces/IStepProps.js';

import { IStepperStepsStyles } from './models/IStepperStepsStyles.js';

const Translate = components.base.Translate.Translate;

export interface IMuiStepProps {
	step: IStepProps;
	steps: IStepProps[];
	activeStep: number;
	stepIndex: number;
	userSelectedValues: TUserSelections;
	handleStep: (index: number) => void;
	orientation: 'horizontal' | 'vertical';
}

const MuiStep: React.FC<IMuiStepProps> = ({
	step,
	steps,
	userSelectedValues,
	activeStep,
	stepIndex,
	handleStep,
	orientation = 'horizontal',
	...props
}) => {
	const styles = useMuiStyles('muiStepperSteps') as IStepperStepsStyles;
	const appStore = useStores<AppStore>('appStore');

	const getStepValue = (index) => {
		if (index < 0 || index >= steps.length) {
			return;
		}
		return userSelectedValues[steps[index].key];
	}

	const userStepSelectedValue = userSelectedValues[step.key];
	const userStepSelectedItem = appStore.stepItemsLookup.get(step.key)?.get(userStepSelectedValue);

	const stepProps: { completed?: boolean } = {};
	const labelProps: {
		optional?: React.ReactNode;
	} = {};

	const isActive = step.key === steps[activeStep].key;
	const isComplete = userStepSelectedValue;
	const defaultIcon = step.stepIcons?.default;
	const activeIcon = step.stepIcons?.active;
	const completeIcon = step.stepIcons?.complete;

	const icon = isComplete ? completeIcon : isActive ? activeIcon : defaultIcon;
	const stepLabel = <Translate>{userStepSelectedItem?.label || step.stepLabel}</Translate>;
	const stepLabelComponent = isActive ? <strong>{stepLabel}</strong> : stepLabel;

	const handlers: Record<string, any> = {};
	/**
	 * Make Steps Clickable When
	 * - it is not the active step.
	 * - it is a previous step.
	 * - it is the next step but only when the steps before it have a value
	 */
	const isClickable = stepIndex < activeStep || (stepIndex > activeStep && getStepValue(stepIndex-1) !== undefined);
	if (isClickable) {
		handlers.onClick = () => handleStep(stepIndex);
	}
	return (
		<Step
			data-selector={`attestation-stepper-display-step-container`}
			sx={styles?.stepper?.step?.styles}
			disabled={!isClickable && !isActive}
			{...stepProps}
			{...props}
			{...handlers}
		>
			<StepLabel
				data-selector={`attestation-stepper-display-step-label`}
				sx={styles?.stepper?.step?.stepLabel?.styles}
				icon={icon?.src && <img width="20px" alt="" {...icon} />}
				{...labelProps}
			>
				{isComplete ? (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: orientation === 'horizontal' ? 'center' : 'flex-start',
						}}
					>
						{/* the above sx styles ensures the correct position for the icon and label, therefore should be fixed */}
						{userStepSelectedItem && step.key === 'country' && userStepSelectedItem.iconSrc && (
							<img
								width="20px"
								style={{ paddingRight: '5px' }}
								src={userStepSelectedItem.iconSrc}
								alt={userStepSelectedItem.iconAlt}
							/>
						)}
						{stepLabelComponent}
					</Box>
				) : (
					stepLabelComponent
				)}
			</StepLabel>
		</Step>
	);
};

export default MuiStep;
