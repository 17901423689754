import React from 'react';
import { components } from '@kurtosys/ksys-app-components';
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel.js';
import RadioGroup from '@mui/material/RadioGroup/RadioGroup.js';
import Typography from '@mui/material/Typography/Typography.js';
import Grid from '@mui/material/Unstable_Grid2/Grid2.js';

import { AppStore } from '../../../../../App/stores/AppStore.js';
import { useMuiStyles } from '../../../../../hooks/useMuiStyles.js';
import { useStores } from '../../../../../hooks/useStores.js';
import { IRadioButtonProps } from '../../models/IRadioButtonProps.js';
import { MuiRadioButton } from '../MuiRadioButton/MuiRadioButton.js';

import { IRadioButtonGroupStyles } from './models/IRadioButtonGroupStyles.js';

const Translate = components.base.Translate.Translate;

export const MuiRadioButtonGroup: React.FC<IRadioButtonProps> = ({
	selectedValue: currentValue,
	selectionItem,
	handleChange,
}) => {
	const appStore = useStores<AppStore>('appStore');
	const styles = useMuiStyles('muiRadioButtonGroup') as IRadioButtonGroupStyles;
	if (!selectionItem.items) {
		return null;
	}

	return (
		<>
			<Typography sx={styles?.label?.styles} data-selector={`attestation-stepper-radio-button-group-label`}>
				<Translate>{selectionItem.label}</Translate>
			</Typography>
			<RadioGroup
				sx={styles?.radioGroup?.styles}
				data-selector={`attestation-stepper-radio-button-group`}
				aria-labelledby="demo-controlled-radio-buttons-group"
				name="controlled-radio-buttons-group"
				value={currentValue?.value ?? ""}
				onChange={handleChange}
			>
				<Grid
					container
					{...selectionItem.gridContainerOptions}
					data-selector={`attestation-stepper-radio-button-inner-grid-container`}
				>
					{selectionItem.items.map(({ label: rawLabel, value, iconSrc, iconAlt, conditional }) => {
						const label = appStore.translate(rawLabel);
						return (
							appStore.isConditionValid(conditional) && (
								<Grid
									{...selectionItem.gridOptions}
									data-selector={`attestation-stepper-radio-button-inner-grid-item`}
									key={`attestation-radio-grid-${value || label}`}
								>
									<FormControlLabel
										data-selector={`attestation-stepper-radio-button-form-control`}
										sx={styles?.radioGroup?.fromControlLabel?.styles}
										value={value || label}
										control={
											<MuiRadioButton
												label={label}
												value={value || label}
												src={iconSrc}
												alt={iconAlt}
												sx={styles?.radioGroup?.fromControlLabel?.radioButton?.styles}
												data-selector={`attestation-stepper-radio-button`}
											/>
										}
										label={
											<Typography
												sx={styles?.radioGroup?.fromControlLabel?.label?.styles}
												data-selector={`attestation-stepper-radio-button-label`}
												fontWeight={currentValue?.value === (value || label) ? 800 : 400}
											>
												{label}
											</Typography>
										}
									/>
								</Grid>
							)
						);
					})}
				</Grid>
			</RadioGroup>
			<Typography
				sx={styles?.description?.styles}
				data-selector={`attestation-stepper-radio-button-group-description`}
			>
				<Translate>{selectionItem.description}</Translate>
			</Typography>
		</>
	);
};
