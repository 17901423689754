import React from 'react';
import Stepper from '@mui/material/Stepper/Stepper.js';

import { useMuiStyles } from '../../../hooks/useMuiStyles.js';

import { IStepperStepsProps } from './models/IStepperStepsProps.js';
import { IStepperStepsStyles } from './models/IStepperStepsStyles.js';
import MuiStep from './MuiStep.js';

export const MuiStepperSteps: React.FC<IStepperStepsProps> = ({
	config,
	activeStep,
	steps,
	handleStep,
	userSelectedValues,
}) => {
	const styles = useMuiStyles('muiStepperSteps') as IStepperStepsStyles;
	return (
		<Stepper
			data-selector={`attestation-stepper-container`}
			sx={styles?.stepper?.styles}
			orientation={config.orientation}
			alternativeLabel={Boolean(config.alternativeLabel)}
			activeStep={activeStep}
		>
			{steps.map((step, index) => {
				// TODO: implement linear stepper to prevent disabled style being clickable, configure optional steps instead. https://mui.com/material-ui/react-stepper/#linear
				return (
					<MuiStep
						key={step.stepLabel}
						step={step}
						steps={steps}
						activeStep={activeStep}
						stepIndex={index}
						userSelectedValues={userSelectedValues}
						handleStep={handleStep}
						orientation={config.orientation}
					/>
				);
			})}
		</Stepper>
	);
};
