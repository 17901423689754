import {stores } from '@kurtosys/app-start';
import { query } from '@kurtosys/ksys-app-template';
import { IManifest } from '@kurtosys/types/appsManager/index.js';
import { GlobalDisclaimerLevel } from '@kurtosys/types/disclaimers/GlobalDisclaimerLevel.js';
import { IGlobalDisclaimerStoreAddOptions } from '@kurtosys/types/disclaimers/IGlobalDisclaimerStoreAddOptions.js';
import { action, computed, makeObservable } from 'mobx';

import { IComponentStyles } from '../../../models/app/IComponentStyles.js';
import { IConfiguration } from '../../../models/app/IConfiguration.js';
import { TStoreContext } from '../../../models/app/TStoreContext.js';
import { AppStore } from '../../App/stores/AppStore.js';
import { IDisclaimerRefConfig } from '../models/IDisclaimerRefConfig.js';
import { IDisclaimerRefProps } from '../models/IDisclaimerRefProps.js';
type QueryStore = stores.QueryStore<IConfiguration, IComponentStyles>;
const StoreBase = stores.base.StoreBase<IConfiguration, IComponentStyles>;

export class DisclaimerStore extends StoreBase {
	constructor(storeContext: TStoreContext, manifest: IManifest) {
		super(storeContext, manifest);
		makeObservable(this);
	}

	@computed
	get appStore() {
		return this.storeContext.get<AppStore>('appStore');
	}

	@action
	async initialize(): Promise<void> {
		this.orchestrateFeatureDisclaimers();
	}

	@computed
	get featuresConfiguration() {
		return this.appStore.appComponentConfiguration?.features;
	}

	@computed
	get queryStore(): QueryStore {
		return this.storeContext.get<QueryStore>('queryStore');
	}

	orchestrateFeatureDisclaimers(): void {
		if (this.featuresConfiguration?.disclaimers) {
			const { disclaimers } = this.featuresConfiguration;
			const disclaimerOptions: IGlobalDisclaimerStoreAddOptions[] = [];
			for (const disclaimer of disclaimers) {
				disclaimerOptions.push({
					disclaimerType: disclaimer.key,
					textQuery: disclaimer.disclaimerQuery,
					level: GlobalDisclaimerLevel.page,
				});
			}
			this.applyGlobalDisclaimers(disclaimerOptions);
		}
	}

	applyGlobalDisclaimers(
		disclaimers: IGlobalDisclaimerStoreAddOptions[],
	): query.disclaimers.GlobalDisclaimerStoreValue[] {
		// TODO: sort out params
		const appKey = `{APP_NAME}_{APP_VERSION}_{CONFIG_NAME}`;
		query.queryUtils.globalDisclaimers.initializeGlobalDisclaimers();
		const globalDisclaimerStore = query.queryUtils.globalDisclaimers.getGlobalDisclaimerStore();
		if (globalDisclaimerStore && disclaimers !== undefined && appKey) {
			return globalDisclaimerStore.add(appKey, {}, this.queryStore, ...disclaimers);
		}
		return [];
	}

	getDisclaimers = (disclaimerRefs: IDisclaimerRefConfig[]): IDisclaimerRefProps[] => {
		const disclaimerStore = query.queryUtils.globalDisclaimers.getGlobalDisclaimerStore();
		const disclaimers = disclaimerStore.get(GlobalDisclaimerLevel.page);
		const disclaimerResults: IDisclaimerRefProps[] = [];
		for (const disclaimerRef of disclaimerRefs) {
			const disclaimerIndex = disclaimers.findIndex(
				(disclaimer) => disclaimerRef.key === disclaimer.disclaimerType,
			);
			if (disclaimerIndex > -1) {
				disclaimerResults.push({
					...disclaimerRef,
					disclaimerValue: disclaimers[disclaimerIndex],
				});
			}
		}
		return disclaimerResults;
	};
}
